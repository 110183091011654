import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import * as React from 'react';
import { Text, View } from 'react-native';
import { Button } from 'react-native-elements';
import { MenuLayoutPluginBase } from './menu-layout-plugin';
import { useNavigation } from '@react-navigation/core'

//  TODO:  Make this work with a DEFAULT component, and then each page is a Modal.  PERHAPS use Plugins for that, SO we don't need to re-write the WHOLE fucking thing just for this MINOR change hM!

const Drawer = createDrawerNavigator();

// AppPluginAPI.registerTabItem = registerTabItem;

export interface DrawerHeader {}
const DrawerHeader = ({ children, navigation }) => {
  return (
    <View style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      {/* TODO:  SHOULD be able to COMPOSE this as well! hM!  THEN we have LOTS of couplers hm!  MAYBE make a way to MANAGE the Coupling systemsh m!  ALSO .. program to INTERFACES so we can auto-couple hm! */}
      {/* TODO:  SHOLD have registration points here too hm!  THIS way things can EXTEND and shit hm! */}
      {/* ALLLLL fucking "classes" are .. are a PATTERN.. an ONTOLOGICAL STRUCUTRE.. a TEMPLATE! that we COUPLE with!  It doesn' have to have ANYTHING to do with FUNCTIONS HM!!!!!  THEN we just SEELCT one ahh!!! THen INJECT based on shit hm!  The POINT is.. or MAIN interface for NOW then is fucking just the NAME.. MUCHH like classes hm, BUT that TOO can be an ontological strucutre with the SAME abstractio MAYBE we can make a thing which dynamically scans and builds UNTIL we're confident we've checked ALL possible ENCODERS HM!!  The CONSTRUCTORS h!  THEn we build it top down and shit ?? Hmm... The MAIN system itself then, is perhaps Pluggable but stabl ein that it operates on the Fundamental Ontology and has a simple primitive interafce that doesn't allow additional extension? Hmmm.. INTERESG!  MAYBE part of that CAn be building this.. but SOMEHERE we need one hard-coded hm! */}
      {/* People OVER-THINK and fucking.. get TRAPPED when they're dealing with ontological structures that are SPECIFIC and trying to MAP between them and shit hm!  This is a LOT of what MATCH is too !Hmm!! JUST showign things are ONTOLOGICALLY EQUIVALANET HM!!!!! LOVE ITTT MMMM!!M!!!!!!!!  Fucking RUSH!!! WHY shold I listen to MYSELF?  Because I need to think and make decisions anyways.  Beacuse I can OBSERVE that SO many people get lost in the structureshm!  They don't TRY to pop OVER them and shit hm!!  That's the KEY HM! Plus.. nobodyss better / worse.. whartever.. it's arrogant to think anything like taht hmm.. the idea is.. people are ALL capable of MAPPING and shti hm!  AND yeh! I'm just MAPIGN HM!  */}
      <View style={{ height: 60, backgroundColor: "#eeeeee", display: 'flex', flexDirection: 'row' }}>
        <Button title="MENUU" onPress={ navigation.toggleDrawer } />
      </View>
      <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      { children }
      </View>
      
    </View>
  );
}

export interface MenuLayoutProps {
  layoutPlugin: MenuLayoutPluginBase;
}

class MenuLayoutBase extends React.Component<MenuLayoutProps, any> {

  public componentDidMount = () => {

    //  TOOD:  IN GENERAL... we have the pattern where a COMPONENT..which is a context which has some state, updated by  framework (React), is pinged from the "outoside".  Let's wrap this all up in ONE mega-framework hm!  THIS way.. rendering ahh!! MAYBEEEE the rendering can BE the Plugin encoding thing hm!!! THat's a cool fucking idea hm!! LOVE that I'm ushig on this hm!!!  I realized that if I think through these LITTLE signals iti REALLY helps, SOOO often!!!  That was one of the best ideas I've ever had.. why did I have that idea?  MAYBE because I'm obsessive, and that MAY have been nature, BUT now I can express these realizations to others hm!  FOr clarity, I mean, the realization that it's an action I CHOSE to perform hm!  NOT the nature thing, that MAY have just helped with the idea/ hm!
    this.props.layoutPlugin.menuItemEmitter.addListener("new-item", () => {

      //  Update Comopnent
      this.setState({});
    })

    this.props.layoutPlugin.menuItemEmitter.addListener("new-above", (above) => {
      this.setState({});
    })

    this.props.layoutPlugin.menuItemEmitter.addListener("new-below", (below) => {
      this.setState({});
    })
  }

  public componentWillUnmount = () => {
    // alert("THIS IS A TEST");

  }
  //  Get Initial
  render() {

    const { menuItems } = this.props.layoutPlugin;

    //  TODO:  Only re-calculate if the screens have changed... which can trigger this with an EVENT, OR we can do the WHOLE thing in React!  Helps to THINK about that, and generalize and ALSO build... I think it's a balance hM!  BOTH help you do the other better!  MANY people just give up!!
    const initialScreen = menuItems.find(item => !!item.isInitial);

    const Test = () => {
      // const navigation = useNavigation();
      React.useEffect(() => {
        return () => {
          
          // navigation.reset({ index: 0, routes: [], routeNames: [], history: [], key: "" });
          // alert(JSON.stringify(navigation.getState()));
          // alert("UNMOUNTING LAYTOU")
          
        }
      }, []);
      return null;
    }

    return (
      <View style={{ display: 'flex', flex: 1 }}>
        { this.props.layoutPlugin.above.map(Comp => <Comp />) }
        <NavigationContainer independent={ true }>
          <Drawer.Navigator screenOptions={{ headerShown: this.props.layoutPlugin.options.showHeader }} screenListeners={{ beforeRemove:  () => alert("REMOCING") }} initialRouteName={ initialScreen?.name || "Default" }>
            {
              menuItems.length > 0 ?
                // NOTE:  Removing the pluggblae Header FOR NOW.. MAY replace later!  Using default.
                // THOGUHT:  We MAY have COMPLEC patterns of implemetnaoti ependiecy dsysems and shti hmfr thim... TOTALLY WY sholdn't wee??? Like COMPLEX conditions to enable a thing.. WOULD be nice to have a away to ExpRESS this visually too houth hm!both what COULD be and what IS hm!!
                // ({ navigation }) => <DrawerHeader navigation={ navigation }><item.component /></DrawerHeader>
                menuItems.map(item => <Drawer.Screen name={ item.name } component={ item.component } options={{ headerRight: item.headerRight }} />) :
                //  TODO:  Make a dynamic, extensible, stylizable default here... I don't like the idea of hard-coding, but we need to connect somwhere right? Hmm.. myabe make it posibl eto add infinite nodes BETWEen though.. so whilel it's hard-coded in a SENSE< it's possible to add thigns bewen it withouot changing the original encondg? Hmm everythingg is then a variale? Hm
                <Drawer.Screen name="Default" component={ () => <Text>Register Routes to Build the Menu Layout</Text> } />
            }
          </Drawer.Navigator>
        </NavigationContainer>
        { this.props.layoutPlugin.below.map(Comp => <Comp />) }
      </View>
    );
  }
}


export const MenuLayout = MenuLayoutBase;
