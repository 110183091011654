/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

import { DTOptions, DT } from "davel";
import { HSDTColor, HSDTColorSchema, RGBAColor, RGBAColorSchema } from "../models";

interface HDTColorOptions extends DTOptions {}

export class HDTColor extends DT<RGBAColor, any> {
  constructor(public options: HDTColorOptions, protected metadata?: any) {
    super(options);
  }

  public async validate(color: RGBAColor): Promise<RGBAColor | undefined> {

    //  Super Validation
    let validatedColor = await super.validate(color);

    //  Handle Undefined
    if (validatedColor === undefined) { return undefined; }

    //  Validate Input
    validatedColor = await RGBAColorSchema.validate(validatedColor);

    //  Validate Params
    return validatedColor;
  }

  public async getElasticSchema() {
    return {
      type: 'nested',
      dynamic: 'strict',
      properties: {
        r: { type: 'keyword' },
        g: { type: 'keyword' },
        b: { type: 'keyword' },
        a: { type: 'keyword' }
      }
    };
  }
}

export const HSDTColorParser = async (sdt: HSDTColor, metadata?: any): Promise<HDTColor> => {

  //  Validate
  const validatedSDT = await HSDTColorSchema.validate(sdt);

  const serializedTypeCopy: any = { ...validatedSDT };
  delete serializedTypeCopy["type"];
  const hTypeColorOptions: HDTColorOptions = { ...serializedTypeCopy };

  //  Create the HTypeColor
  const hTypeColor = new HDTColor(hTypeColorOptions, metadata);
  return hTypeColor;
};
