import { SDT, SDTSchemaProperties, SDTSerializedSchemaProperties } from '../parser-core';
import * as yup from 'yup';
import { DTText, DTTextOptions, DTObject, DTOption } from '../types';
import { SDTObject } from './object';
import { SDTYup } from '../util';

export interface SDTText extends SDT {
  type: 'text';
}

const SDTTextYup = SDTYup.shape({
  type: yup.string().required().matches(/text/),
});

export async function SDTTextParser<MetadataType = any> (serialized: SDTText, metadata?: MetadataType) {

  //  Validate
  const valid = SDTTextYup.validateSync(serialized);
  if (!valid) { throw new Error(`Text schema validation failed.`); }

  const serializedCopy: any = { ...serialized };
  delete serializedCopy['type'];
  const stringOptions: DTTextOptions = { ...serializedCopy };

  //  Create the DTText
  const dtText = new DTText(stringOptions, metadata);
  return dtText;
}

//  Schema to Validate an Text SDT
export const SDTTextSerializedSchema: SDTObject = {
  type: 'object',
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: 'option', options: ['text'] }
  }
};

export const SDTTextSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['text'] })
  }
});
