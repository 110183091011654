import { deserializeSDT, SDT } from '../parser-core';
import { DT, DTOptions } from '../type-core';

//
//  DTSDT:  Validates a Serialized Davel Type by attempting to de-serialize it.  It does not use the "SDT(X)Schema" definitions.
//          This way, any SDT can be validated.
//  TODO:  Consider using the "SDT(X)Schema" for validation instead of de-serializing.  We could remove this in place of an "OR" type which will be valid if the provided value matches any of a given set of options.  Then, we can use the SDT(X)Schema" in that option list.
//
export interface DTSDTOptions extends DTOptions {
  itemType?: string; //  NOTE:  I would put AnyDTType but then extensions would fail.
  //  TODO:  Switch from string to SDT / DT??
}

export class DTSDT<MetadataType = any> extends DT<SDT, MetadataType> {
  public name = 'SDT';

  constructor(public options: DTSDTOptions = {}, protected metadata?: MetadataType) {
    super(options);
  }

  public async validate(input: SDT): Promise<SDT | undefined> {

    //  Super Validation
    input = await super.validate(input);

    //  Handle Undefined
    if (input === undefined) { return undefined; }

    //  Validate Input
    const { itemType } = this.options;
    if (itemType) {
      if (input.type !== itemType) {
        this.failValidation(input, `The Davel Type (DT) provided must be of type '${itemType}' not '${input.type}' which was the type that was provided.`);
      }
    }

    try {
      await deserializeSDT(input);
    } catch (err) {
      this.failValidation(input, `An error occurred while attempting to deserialize the provided SDT:\n\n'${ err }'`);
    }

    return input;
  }

  public async getElasticSchema(topLevel: boolean) {
    return { type: 'nested', dynamic: 'true' }; //  TODO:  This will allow DTSDT to be stored in an array and stored in elastic without conversion.  BUT, it may be expensive...
  }
}
