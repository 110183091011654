import { DTObject } from './types';

//  TODO:  Consider hiding these implementation details and providing first-class support for
//         custom types.

//  TODO:  It really doesn't make sense to have Elastic concepts hard-coded here.  Maybe put this
//         functionality in the App logic or provide first-class support for custom serializers.

//  TODO:  Create a DT to support aggregate types.  For example, "DTNumber AND DTText".

//  CONVENTION:  Prefix Davel Types with:  "DT"
//               Prefix Serialized Davel Types with:  "SDT"
//               For example, a Text type would be called "DTText" and "SDTText".

//  NOTE:  Every DT is an instance of a class with properties that can be used for validation.
//         Every SDT is a POJO which encodes the DT (it should look similar to the DT constructor options).

//  TODO:  Create a formal way to compare DTs
//         Class is OK, but what about when we need to know if the settings are the same (or one is a subset like string length).
//         Ah, like a "compatibility" function to determine whether or not A is compatible with B.

//  TODO:  Consider default types, with an option specifying whether the default should be used if unspecified.

//  TODO:  Handle undefined properties on objects... should the property be removed from the object?
//         I suggest we add an object property: 'removeUndefined' and change the 'required' property to 'allowUndefined'.

//  TODO:  Support object extension.  Shouldn't be too difficult with the SDT serialization / de-serialization.
//         Just serialize the DT, then merge, then de-serialize.

//  TODO:  Consider using the Joi / Yup model (Joi.string().required()...).
//         It uses function chaining instead of new instances for each validator.
//         This may help reduce instantiation costs.

//  TODO:  Consider making 'input' immutable instead of mutating its value on validation.  Consider immutable.js.

export interface DTOptions {
  metadata?: any;
  required?: boolean;
}

type RegisteredMethod = (params: any) => void;

/**
 * Base Class of all Davel Types
 */
export class DT<ItemType, MetadataType = any> {

  public name: string;
  public registeredMethods: { [name: string]: RegisteredMethod } = {};

  constructor(protected options: DTOptions, protected metadata?: MetadataType) {
    this.options.metadata = options.metadata || {};
    this.options.required = !!options.required;
  }

  protected failValidation = (input: any, reason?: string) => {
    const reasonString = reason ? `\n\nDue to the following problem: '${ reason }'\n\n\n\n` : '\n\n\n\n';

    throw new Error(`Davel validation failed for type '${ this.name }' with input:\n\n'${ JSON.stringify(input) }'${ reasonString }`);
  }

  //  TODO:  This is nice and all, but it breaks the typical usage pattern... hmm...
  public async registerMethod(name: string, method: RegisteredMethod) {
    if (this.registeredMethods[name]) { throw new Error(`The '${name}' method is already registered on this DT.`); }
    this.registeredMethods[name] = method;
  }

  public async invokeMethod(name: string, params: any) {
    const method = this.registeredMethods[name];
    if (!method) { throw new Error(`The '${name}' method is not registered.`); }
    const res = await method(params);
    return res;
  }

  public async validate(input: ItemType): Promise<ItemType | undefined> {
    //  Unpack Options
    const { required } = this.options;

    //  Handle Missing Input
    if ((input === undefined) || (input === null)) {
      if (required) {
        throw new Error(`A value was not provided for a required field.`);
      } else {
        return undefined;
      }
    }
    return input;
  }

  //  TODO:  Should be registered as an "action" with the DTs?  Similar to Hessia, we have systems (types) and they all contribute?  Hmm... not sure if it works the same.
  public async getElasticSchema(topLevel: boolean): Promise<any> {
    throw new Error('Method must be implemented by a sub-class.');
  }
}


export const getElasticSchema = async (object: DTObject) => {
  const schema = await object.getElasticSchema(true);
  return schema;
};



//  NOTE:  Istance needs to fuck the constructor ughh
//  NOTE:  TO add INSTACE properties, we build a REGISTER method that we invoke fucking hmm... we CAN do this with entities / associations hm!  BUT we can do it perhaps statically? Hmm... interesting.  The idea is we want to fuck the CLASS in this case hm!  SO, when we add that stuff, it'll be hmm.. ONE question is whether or not we SHOULD do it like this? Hmm.. We CAN of course, but WHY not just do it with our OWN structure and MAP to a class?  INSTEAD of supporting both directions? Hm!  Basically my OWN wrapper for that stuff? Hmm... MAYBE.  AHHH!!! ONE thing I might want to do is make ASSOCIATIONS and shit to things like class members, etc hm!  Being able to see all "Classes" and show associated "Static Props", and etc.. can be nice.  We CAN do this and shit, WHY group by usage? Hmm... Use an onology? Hm

const addStaticProp = (Class: any, name: string, prop: any) => {
  Class[name] = prop;
};

const addInstanceProp = (Class: any, name: string, prop: any) => {

};


//
//  An Extensible Class
//  TODO:  Consider
//
// class ExtensibleClass {
//   public static registerMethod = (name: string, method: any) => {
//     const _this = this as any;
//     if (_this[name] != undefined) {
//       console.log(`Overriding '${ name }'`);
//     }
//     _this[name] = method;
//   }
// }