import { DTObject, DTKeyword, DTBoolean, SDTObject } from "davel";

//  Invokes a single, global function.
export interface HInvocation {
  functionName: string;
  params?: any;
}

export const HInvocationSchema = new DTObject({
  extensible: false,
  required: true,  //  TODO:  Does it make sense to have "required" here?  For DTs associated with Object properties, it might be helpful if the parent property can override the child type? (applies to serialized schema as well)
  properties: {
    functionName: new DTKeyword({ required: true }),  //  TODO:  Switch to a validating type when (if) it's available.  (applies to serialized schema as well)
    params: new DTObject({ required: false, extensible: true }),  //  TODO:  Replace with param type?  Actually... should be conditionally dependent on the function params... New HDT?  (applies to serialized schema as well)
  }
});

export const HInvocationSerializedSchema: SDTObject = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    functionName: { type: "keyword", required: true },
    params: { type: "object", required: false, extensible: true }
  }
};

export interface HRequest {
  invocation: HInvocation;
  middlewareParams?: any;
  callerId?: string;  //  Optional ID to identify the calling system.
}

export interface RequestNode {
  req: HRequest;
  startTime: Date;
  endTime?: Date;
  parent: RequestNode;
  children: RequestNode[];
}

export interface HRequestContext {
  current: RequestNode;
  timestamp: string;
  [key: string]: any;
}

export const HRequestSchema = new DTObject({
  extensible: false,
  required: true,
  properties: {
    invocation: HInvocationSchema,
    middlewareParams: new DTObject({  //  TODO:  This can be dynamically updated as new Plugins are added... This implies we're storing the Schema in Habor.  Support dynamic schemas.
      extensible: true,
      required: false
    })
  }
});

export const HRequestSerializedSchema: SDTObject = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    invocation: HInvocationSerializedSchema,
    middlewareParams: {
      type: "object",
      extensible: true,
      required: false
    }
  }
};

export interface HStatement {
  assign?: {
    name: string;
    constant?: boolean;
  };
  return?: boolean;
  functionName: string;
  params?: any;  //  TODO:  Replace with param type?
}

export const HStatementSchema = new DTObject({
  extensible: false,
  properties: {
    assign: new DTObject({
      required: false,
      extensible: false,
      properties: {
        name: new DTKeyword(),
        constant: new DTBoolean({ required: false })
      }
    }),
    return: new DTBoolean({ required: false }),
    functionName: new DTKeyword({ required: true }),  //  TODO:  Switch to a validating type when (if) it's available.
    params: new DTObject({ required: false, extensible: true }),  //  TODO:  Replace with param type?  Actually... should be conditionally dependent on the function params... New HDT?
  }
});

export const HStatementSerializedSchema = {
  type: "object",
  extensible: false,
  properties: {
    assign: {
      type: "object",
      required: false,
      extensible: false,
      properties: {
        name: { type: "keyword", required: true },
        constant: { type: "boolean", required: false }
      }
    },
    return: { type: "boolean", required: false },
    functionName: { type: "keyword", required: true },
    params: { type: "object", required: false, extensible: true }  //  TODO:  Replace with param type?  Actually... should be conditionally dependent on the function... New HDT?
  }
};