/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

import { handler, Program, CorePluginClass } from "./core-plugin";

//  TODO-IMPORTANT:  Check for CIRCULAR dependencies!  MAYBE we should ERR, or find some way to continue?  I THINK we should Err for now.
//  IDEA:  Make a visualization of the dependencies being built!  Update in real time as they are installed.
//  CONSIDER:  In what cases will a Plugin NOT be installable?  One is a circular dependency.

/**
 * Build the Tree
 *
 * We need to build a tree, where siblings are independent, and a child is dependent upon a parent.
 * The end result is a set of independent Plugins to be installed in the top.  We should NOT install a Plugin more than once.
 */

//  Creates a tree of Parents leading to Children.
export interface Node<T> {
  id: string;
  parents: Node<T>[];
  children: Node<T>[];
  original: T;
  exports?: any;
  isInstalled: boolean;
}

export interface CorePluginRealization {
  pluginClass: typeof CorePluginClass;
  plugin: CorePluginClass;
}

interface PluginMap {
  [id: string]: CorePluginRealization;
}

//  If converter is specified, it will transform the obj to a suitable format
//  TODO:  Maybe converter should not support "children"?

//  NOTE:  A Node ALWAYS has the same set of parents, so we only need to process once.
//  The goal is to find our children!  We want to return an Array of "top-level" nodes.
//  Children are attached from other nodes.

//  When we process a Node, we need to add it to its parents.

export class HaliaStack {
  public installedPlugins: PluginMap = {};

  //  Define the Program
  public code: any[] = [];
  public codeRegister = (handler: handler) => {
    this.code.push(handler);
  };
  public program: Program = {
    registerCode: this.codeRegister,
  };

  public getPlugin = (pluginId: string) => {
    return this.installedPlugins[pluginId];
  };

  /**
   * Returns the set of Root Nodes
   * CONSIDER:  Could we save time by providing a "converter" function to transform each object to a Node without pre-processing?
   * @param nodes
   */
  public nestNodes = async <T>(nodes: Node<T>[]): Promise<Node<T>[]> => {
    //  Node Log:  Marks whether a node has been processed
    const nodeLog: { [id: string]: boolean } = {};

    //  Define a list of Root Nodes.
    const rootNodes: Node<T>[] = [];

    //  Recursively processes a node and its parents.
    const processNode = async (node: Node<T>) => {
      //  Unpack
      const { id, parents } = node;

      //  Check Existing
      if (!nodeLog[id]) {
        //  Set the Node
        nodeLog[id] = true;

        //  Process Parents
        for (const parent of parents) {
          await processNode(parent);
          parent.children.push(node);
        }

        //  Check Root
        if (parents.length == 0) {
          rootNodes.push(node);
        }
      }
    };

    //  Process Nodes
    for (const node of nodes) {
      await processNode(node);
    }

    return rootNodes;
  };

  public registerCorePluginClass = (pluginClass: typeof CorePluginClass, options?: any) => {
    const pluginInst = new (pluginClass as any)(options) as CorePluginClass;

    const plugin: CorePluginRealization = {
      pluginClass,
      plugin: pluginInst
    };

    this.installedPlugins[pluginClass.details.id] = plugin;
  };


  public register = (pluginClass: typeof CorePluginClass, options?: any) => {
    this.registerCorePluginClass(pluginClass, options);
  };

  /**
   * TODO:  Currently we use ALL installed Plugins.  In the future, consider only using those specified by the user.
   * @param program
   * @param pluginIds
   */
  public build = async () => {
    //  Get the App Plugins (plugins used to build this app)
    //  IDEA:  MAYBE we should call them "Features" or "Elements"?
    const appPlugins = Object.keys(this.installedPlugins).map(
      (pluginName) => this.installedPlugins[pluginName]
    );
    // const appPlugins = pluginIds.map(pluginId => getPlugin(pluginId));

    //  Build the Nodes
    //  TODO:  Add these to a class to encapsulate local state?
    const nodeCache: { [pluginId: string]: Node<CorePluginRealization> } = {};
    const buildNode = (plugin: CorePluginRealization): Node<CorePluginRealization> => {
      //  Check Existing
      const existing = nodeCache[plugin.pluginClass.details.id];
      if (existing) {
        return existing;
      }

      //  Define the Node
      const node: Node<CorePluginRealization> = {
        original: plugin,
        id: plugin.pluginClass.details.id,
        parents: [],
        children: [],
        isInstalled: false,
      };

      //  Build Parents
      const parentPluginIds = plugin.pluginClass.details.dependencies;
      const parentNodes = parentPluginIds.map((parentPluginId) => {
        const parentPlugin = this.getPlugin(parentPluginId);
        if (!parentPlugin) {
          throw new Error(`The '${ parentPluginId }' plugin required by the '${ plugin.pluginClass.details.id }' plugin has not been installed.`);
        }
        const cachedNode = nodeCache[parentPlugin.pluginClass.details.id];
        if (cachedNode) {
          return cachedNode;
        }
        const parentNode = buildNode(parentPlugin);
        parentNode.children.push(node);
        return parentNode;
      });

      //  Attach Parents
      node.parents = parentNodes;

      //  Attach the Node
      nodeCache[node.id] = node;
      return node;
    };

    //  Get Node Plugins
    const nodes = appPlugins.map((plugin) => buildNode(plugin));

    //  Nest Nodes
    const rootNodes = await this.nestNodes(nodes);

    //  Build the App Depth-First
    const exportMap: { [name: string]: any } = {};

    //  Function to obtain imports
    //  TODO:  Cache this?  More generally, cache all pure functions which are reasonably called with the same input?
    const getExports = (dependencies: string[]) => {
      //  TODO:  Check undefined?
      const importMap: { [name: string]: any } = {};
      dependencies.forEach((dependencyId) => {
        importMap[dependencyId] = exportMap[dependencyId];
      });
      return importMap;
    };

    const setExports = (pluginId: string, value: any) => {
      //  TODO:  Check existing?
      exportMap[pluginId] = value;
    };

    //  Function to Process Node
    //  TODO:  Consider async support.
    //  NOTE:  I'm not using the class constructor because we MAY want to support async at some point.
    const processNode = async (node: Node<CorePluginRealization>) => {
      //  Unpack
      const { original: plugin, children } = node;
      const {
        details: { id, dependencies },
      } = plugin.pluginClass;

      //  Gather Exports (to be used as imports)
      //  NOTE:  All imports should have been initialized by their respective plugin install script.
      const imports = getExports(dependencies);

      //  Install
      //  TODO:  I THINK it makes sense to support ASYNC install functions!
      let exports: any;
      try {
        exports = await plugin.plugin.install(this.program, imports);
      } catch (err) {
        throw `An error occurred while installing the '${ plugin.pluginClass.details.name }' Halia Plugin: ${ JSON.stringify(err) }`;
      }

      // if (exports == undefined) {
      //   //  TODO:  In the future, consider relaxing this constraint, ESPECIALLY for class-based Plugins which default to "this" as the export.
      //   throw new Error(`No exports for Plugin '${ plugin.details.id }'.  Every Plugin must provide an 'exports' object.`);
      // }

      //  Update the Node
      //  NOTE:  While we COULD check the exports, I think an explicit flag makes things more readable.
      node.isInstalled = true;

      //  Set Exports
      setExports(id, exports);
      node.exports = exports;

      //  Process Children
      for (const child of children) {

        //  Check Installed
        //  NOTE:  If something is a child, then it should either NOT be installed because there are parent dependencies remaining, OR not installed and all dependencies are met.  We should NOT ever be installed AND a child.
        if (child.isInstalled) {
          continue;
        }

        //  Check Child Dependencies
        //  NOTE:  It's possible for a plugin to depend upon another plugin which has NOT yet been installed, BUT one of its parents has been installed.  Therefore, we want to make sure we ONLY run a plugin once ALL dependencies were installed!
        //  CONSIDER:  Explicitly model with a state machine and switching a State to "ready" instead of checking the state conditions to determine if we're in the given state?
        //  NOTE:  A node is in the "pending" state while it's waiting for its parents to initialize.
        const isPending = child.parents
          .map((parent) => parent.isInstalled)
          .includes(false);
        if (isPending) {
          continue;
        }

        //  Process the Child
        await processNode(child);
      }
    };

    //  Process Root Nodes
    for (const node of rootNodes) {
      await processNode(node);
    }

    return rootNodes;
  };

  public run = async () => {
    const results = [];
    for (const handler of this.code) {
      const res = await handler(); //  TODO:  Instead of passing NOTHING here, pass the DEPENDENCIES that were defined for the Plugin.  This way, the Plugins don't need to reach into the Global context?  We can do this by wrapping in an object?
      results.push(res);
    }
    return results;
  };
}

// const getPlugin = (pluginId: string): CorePlugin => {
//   return installedPlugins[pluginId];
// };

//  REFERENCE:  https://semver.org
// export interface FeatureVersion {
//   major: number;  //  API Change
//   minor: number;  //  Feature Change
//   patch: number;  //  Patch Change
// }

// export interface Feature {
//   name: string;
//   description?: string;
//   version: FeatureVersion;
//   dependencies?: { [name: string]: FeatureVersion };

//   //  TODO:  SHOULD we support "exports" for an explicit API?
//   // exports: {

//   // }

//  TODO:  Should we support "imports" for explicit imports from the dependencies?

//  CONCERN:  Is this too similar to Nest.js?  Is there a way to run Nest.js WITHOUT the server?  Are we going to do anything special to support "Feature APIs", OR will we just expose functions???
//            IF we just expose functions, we MAY be able to just use Nest?  Hmmm..  I'd ALSO like Halia to be extensible too though!  Nest doesn't seem particularly extensible?

// }

//  EXPLANATION:  We give users the ability to define their own "Feature APIs" which can be acted upon by other, dependent Plugins.  This makes a stack of Plugins.
//  CONCERN:  What do we do when a Plugin depends on the influence of another, but NOT its API?  It's JUST for installation purposes?  FOR NOW, let's just treat it like a normal dependency?
//  PLAN:  I suggest we start with a flat list, MUCH like npm.  THEN, we can work on resolution order?

//  CONCERN:  I REALLY want to make sure it's possible to create "Registration Points" in a GENERAL way.  Ah!  I think I JUST realized!  A distinction between registering a new object which is interpreted and a specific function / UI element, is the "abstraction".  In one, we are in the APPLICATION level language, and that's where we're communicating.  In the other, we are at a lower level, and we're communicating with functions and similar low level primitives??  I suggest we try to build tools to help facilitate BOTH styles, BUT we ALSO want to avoid "over-engineering" such that we end up with something overly complex...

//  TODO:  FOR NOW, we have ONE pre-launch channel ("install").  In the future, consider additional, AND additional "launch" channels?  MAYBE remove the distinction between the two?
//  export const start = (configPath: string) => {

//   //  Get the Config
//   // const config = fs.readFileSync(configPath);

//   //  Get the App Plugins
//   //  TODO:  Differentiate between VERSIONS!  CONSIDER making sure only ONE version is allowed in the stack at a time.  Why?  Because it may install into entities differently than expected... how can we check if a version is compatible?  In reality, a version change, COULD effectively be an ENTIRELY different package!  I think we NEED something stronger than the standard major, minor, patch naming convention for version management!  I think we need something generic?  I DO like the Major distinction, because otherwise the API SHOULD be the same?  BUT, what if it USES or DEPENDS upon different APIs??

//   //  Build the App
//   // buildApp(program, appPlugins);

//   // const express = ExpressPlugin.install(program, []);
//   // const web = WebPlugin.install(program, [ExpressPlugin]);
//   // const habor = HaborPlugin.install(program);
//   // ObjectPlugin.install(program, [ReactorPlugin, ExpressPlugin]);

//   //  Run the App
//   // await runApp(program);

// }

// export const initApp = async (): Promise<any> => {

//   //  Run the Program built with the Plugins
//   //  TODO:  Do this based on a config entry like Django?
//   //  NOTE:  Plugins interface at the APP level.  An "App" is built from a composition of Plugins!
//   //         EACH Plugin may 'export' functionality that can then be used by dependent Plugins.
//   //         The "program" is not run until ALL Plugins have been installed.

//   //  TODO:  Each Plugin should have a predictable "name" which is used to obtain the exports?

//   //  IDEA:  Release this "Plugin" package!  It's a tool to build de-coupled apps with a Pluggable architecture.
//   //  TODO:  Support VERSIONING.  For now, we'll just keep a SINGLE version.

//   //  TODO:  Build a dependency injector to inject these with their dependencies!

//   //  TODO:  Support Params?

//   const installedPlugins: { [name: string]: CorePlugin } = {
//     express: ExpressPlugin,
//     web: WebPlugin,
//     habor: HaborPlugin,
//     // object: ObjectPlugin
//   };

//   const appPlugins = ["express", "web", "habor"];

// };

//  TODO:  Change the name from "install" to "initialize"?
//  TODO:  Check to ensure ALL Plugins have been "initialized".
