import * as yup from 'yup';
import { DT, DTOptions } from '../type-core';

export interface DTArrayOptions<ItemType, MetadataType = any> extends DTOptions {
  itemType: DT<ItemType, MetadataType>; //  TODO:  Consider more complex array type definitions (like yup).
}

export class DTArray<ItemType, MetadataType = any> extends DT<ItemType[], MetadataType> {

  public name = 'Array';

  constructor(protected options: DTArrayOptions<ItemType, MetadataType>, protected metadata?: MetadataType) {
    super(options, metadata);
    if (!options.itemType) { throw new Error(`The 'itemType' option must be specified for a DTArray.`); }
  }

  public async validate(input: ItemType[]): Promise<ItemType[] | undefined> {

    //  Super Validation
    input = await super.validate(input);

    //  Handle Undefined
    if (input === undefined) { return []; }

    //  Validate Input
    const valid = yup.array().validateSync(input);
    if (!valid) { this.failValidation(input); }

    const { itemType } = this.options;
    const validatedInput = [];

    //  Validate all input elements are the expected type.
    for (let i = 0; i < input.length; i++) {
      const inputElem = input[i];
      try {
        const validatedElem = await itemType.validate(inputElem);
        validatedInput.push(validatedElem);
      } catch (err) {
        this.failValidation(input, `Validation failed for array element with index '${ i }'.  Here's the validation error:\n\n${ err }`);
      }
    }

    return validatedInput;
  }

  //  TODO:  Elastic concept shouldn't be hard-coded here...
  //         Maybe extract to a separate module for converting a type tree into other trees?
  public async getElasticSchema(topLevel: boolean) {

    //  TODO:  Deal with nested arrays!  Possibly use a nested object for each tuple.
    if (this.options.itemType instanceof DTArray) {
      throw new Error('Nested arrays are not currently supported');
    }

    //  Generate the Schema for Array Children
    //  NOTE:  The schema will look like this:  ( { ... } )
    const elementSchema = this.options.itemType.getElasticSchema(topLevel);

    //  NOTE:  Arrays are not explicitly defined in ES.  Also, they must contain only a single data type.
    return elementSchema;
  }
}

