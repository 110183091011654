import { SDTObject } from 'davel';
import { Noun } from './noun';
import { RGBAColorSerializedSchema, RGBAColor } from './color';
import { HaborIconSelection } from './icon';

//  CONSIDER:  Consider removing "style" from this object and styling with the "style" object.  FOR NOW, we can leave it coupled.

export interface NamedObject {
  name: string;
  description?: string;
  icon?: HaborIconSelection;
  color?: RGBAColor;
}

export const namedObject: Noun = {
  id: "namedobject",
  name: "NamedObject",
  description: "A Named Object",
  abstract: true,
  properties: {
    name: {
      name: "name",
      type: { type: "keyword", required: true }
    },
    description: {
      name: "description",
      type: { type: "text", required: false }
    },
    icon: {
      name: "icon",
      type: { type: "custom-type", typeId: "icon", required: false }
    },
    color: {
      name: "color",
      type: { type: "custom-type", typeId: "Color", required: false }  //  TODO:  Required flag is still weird... it's a decision that the Object makes, not the property.
    },
  }
};

export const namedObjectSerializedProperties = {
  name: { type: 'keyword', required: true },
  description: { type: 'keyword', required: false },
  icon: { type: "custom-type", typeId: "icon", required: false },
  color: { type: "custom-type", typeId: "Color", required: false }
};

export const namedObjectSerializedSchema: SDTObject = {
  type: 'object',
  extensible: false,
  required: true,
  properties: namedObjectSerializedProperties
};