import { HUserFunction } from "./function";

export enum HookType {
  Before = 'before',
  After = 'after'
}

export interface Hook {
  hookName?: string;
  hookDescription?: string;
  hookType: HookType;
  hookFunction: HUserFunction;
}
