import { SDT, SDTObject, SDTSerializedSchemaProperties, DTObject, SDTSchemaProperties, DTOption, DTKeyword } from "davel";

export interface HaborIconSelection {
  name: string;
  type: string;  //  TODO:  Could be typed as IconType if we reference "ReactNativeElement".
}

//  NOTE:  We use the word "Schema" to denote any DTObject or SDTObject which matches a given pattern...  It IS true that this is an SDT for the Object type, but when we name a schema, we're naming regarding the object that the schema validates.
export const HaborIconSerializedSchema: SDTObject = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    //  TODO:  Use the "options" array to limit the selection.  AND, somehow make sure each name selection is bound to the corresponding type selection??  FOR NOW, maybe we can add logic in the custom type??  Hmmm...  Ideally we'd do it declaratively so the UI knows how to understand without running a processor?
    name: { type: "keyword", required: true },
    type: { type: "keyword", required: true }
  }
};

export const HaborIconSchema: DTObject = new DTObject({
  extensible: false,
  required: true,
  properties: {
    //  TODO:  Same as above.
    name: new DTKeyword({ required: true }),
    type: new DTKeyword({ required: true })
  }
});
