import * as yup from 'yup';
import { DT, DTOptions } from '../type-core';

//  TODO:  Support default option.
export interface DTOptionOptions extends DTOptions {
  options: string[];
}

//  TODO:  Somehow inherit from 'keyword' to support type merging.
export class DTOption<MetadataType = any> extends DT<string> {
  public name = 'Option';

  constructor(public options: DTOptionOptions, protected metadata?: MetadataType) {
    super(options);
    if (!options.options) { throw new Error(`The 'options' option must be specified for a DTOption`); }
  }

  //  TODO:  Build using RULES! HM!  THIS way we can mix and match and stuff hm!
  public async validate(input: string): Promise<string | undefined> {

    //  Super Validation
    input = await super.validate(input);

    //  Handle Undefined
    if (input === undefined) { return undefined; }

    //  Validate Input
    const { options } = this.options;
    const optionRegex = new RegExp(options.join('|'));
    const valid = await yup.string().matches(optionRegex).isValid(input);
    if (!valid) { this.failValidation(input); }
    return input;
  }


  //  TODO:  Inject with a Plugin
  public async getElasticSchema() {
    return {
        type: 'keyword' //  TODO:  Consider using Elastic enum (if it exists)?
    };
  }
}
