import * as yup from 'yup';
import { deserializeSDT, SDT, SDTSchemaProperties, SDTSerializedSchemaProperties } from '../parser-core';
import { DT } from '../type-core';
import { DTBoolean, DTObject, DTObjectOptions, DTOption, DTSDT } from '../types';
import { SDTYup } from '../util';

export interface SDTObject extends SDT {
  type: 'object';
  properties?: { [name: string]: any }; //  TODO:  I WOULD put 'AnySDT' as the type, but then type extensions fail...
  extensible?: boolean;
  itemType?: SDT;
}

const SDTObjectYup = SDTYup.shape({
  type: yup.string().required().matches(/object/),
  properties: yup.object(), //  TODO:  Add constraints to this!  Yup doesn't seem to support 'pattern' match on objects.
  extensible: yup.boolean()
});

export async function SDTObjectParser<MetadataType = any> (serialized: SDTObject, metadata?: MetadataType) {

  //  Validate
  const valid = SDTObjectYup.validateSync(serialized);
  if (!valid) { throw new Error(`Object schema validation failed.`); }

  //  Unpack
  const { properties = {}, itemType } = serialized;

  //  Create Property Tree
  const parsedProperties: { [ name: string ]: DT<any> } = {};
  const propNames = Object.keys(properties);
  for (let i = 0; i < propNames.length; i++) {
    const propName = propNames[i];
    const propSchema = properties[propName];
    try {
      const parsedProp = await deserializeSDT(propSchema, metadata);
      parsedProperties[propName] = parsedProp;
    } catch (err) {
      throw new Error(`Failed to deserialize property '${ propName }' with schema:\n\n'${ propSchema }'\n\n${ err }`);
    }
  }

  //  Parse the Item Type
  let dtItemType;
  try {
    dtItemType = itemType ? await deserializeSDT(itemType, metadata) : undefined;
  } catch (err) {
    throw new Error(`Failed to deserialize the 'itemType' SDT:\n\n${ itemType }\n\n${ err }`);
  }


  //  Create the Options
  const serializedCopy: any = { ...serialized };
  delete serializedCopy['type'];
  const objectOptions: DTObjectOptions = { ...serializedCopy, properties: parsedProperties, itemType: dtItemType };

  //  Create the DTObject
  const dtObject = new DTObject(objectOptions, metadata);
  return dtObject;
}

//  Schema to Validate an Object SDT
//  NOTE:  A LITTLE twilight zone-ish / ecusive, because this IS an instance of the thing we'e modeling!
export const SDTObjectSerializedSchema: SDTObject = {
  type: 'object',
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: 'option', options: ['object'] },
    //  TODO:  Encode "itemType" and the TYPE equivalent for each attribute!
    //  CONSIDER:  We may want to support MULTIPLE types for properties, or... an expression for arbitrary complexity.
    properties: { type: 'object', required: false, extensible: true, itemType: { type: 'sdt' } },
    extensible: { type: 'boolean', required: false }
  }
};

export const SDTObjectSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['object'] }),
    //  TODO:  Encode "itemType" and the TYPE equiement fo each attbute!
    properties: new DTObject({ required: false, extensible: true, itemType: new DTSDT() }),
    extensible: new DTBoolean({ required: false }),
  }
});
