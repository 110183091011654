import { SDTAny, SDTArray, SDTBoolean, SDTDate, SDTKeyword, SDTNumber, SDTObject, SDTOption, SDTSDT, SDTText } from './parsers';
import { SDTBinary } from './parsers/binary';
import { DT } from './type-core';
import { DTBoolean, DTKeyword, DTObject } from './types';
import { selectedTypespace } from './typespace';

export interface SDT {
  metadata?: any;
  required?: boolean;
  type: string;
}

export const SDTSchemaProperties = {
  metadata: new DTObject({ required: false, extensible: true }),
  required: new DTBoolean({ required: false }),
  type: new DTKeyword({ required: true })
};

export const SDTSerializedSchemaProperties = {
  metadata: { type: 'object', required: false, extensible: true },
  required: { type: 'boolean', required: false },
  type: { type: 'keyword', required: true }
};

export type SDTDeserializer = (schema: SDT) => Promise<DT<any>>;


//
//  Parser Definitions
//
export const allDTRegex = /(keyword|text|number|object|boolean|date|option|array|sdt|any|binary)/;
export type AllDTType = 'keyword' | 'text' | 'number' | 'object' | 'boolean' | 'date' | 'option' | 'array' | 'sdt' | 'any' | 'binary';

export type AllSDT = SDTKeyword | SDTText | SDTNumber | SDTObject | SDTBoolean | SDTDate | SDTOption | SDTArray | SDTSDT | SDTAny | SDTBinary;


export const registerSDTDeserializer = (typeName: string, parser: SDTDeserializer) => {
  if (selectedTypespace.typeToParser[typeName]) { throw new Error('A parser has already been registered for the given type.'); }
  selectedTypespace.typeToParser[typeName] = parser;
};

//  TODO-IMPORTANT:  Seriously consider making this synchronous... Should the async validation happen outside of Davel?
//  CONSIDER:  Standardize the design and errors of the deserializer, perhaps similar to the DT (which uses class inheritance).
export const deserializeSDT = async (sdt: SDT, metadata?: any): Promise<DT<any>> => {

  //  Validate Input
  const { type } = sdt;
  if (!type) { throw new Error("Invalid schema.  A 'type' was not specified in the call to 'deserializeSDT'."); }

  //  Parse the Schema
  const parser: (sdt: SDT, metadata?: any) => Promise<DT<any>> = selectedTypespace.typeToParser[type];
  if (!parser) { throw new Error(`The specified type (${type}) has no registered parser.`); }
  try {
    const dt = await parser(sdt, metadata);
    return dt;
  } catch (err) {
    throw new Error(`SDT Deserialization failed for type '${ type }' with SDT: \n\n${ sdt }\n\n${ err }`);
  }
};


