import * as yup from 'yup';
import { DT, DTOptions } from '../type-core';

//
//  DTKeyword:  An indexed text field for structured data.
//  REFERENCE:  https://www.elastic.co/guide/en/elasticsearch/reference/current/keyword.hdtl
//

export interface DTKeywordOptions extends DTOptions {}

export class DTKeyword<MetadataType = any> extends DT<string, MetadataType> {

  public name = 'Keyword';

  constructor(public options: DTKeywordOptions = {}, protected metadata?: MetadataType) {
    super(options);
  }

  public async validate(input: string): Promise<string | undefined> {

    //  Super Validation
    input = await super.validate(input);

    //  Handle Undefined
    if (input === undefined) { return undefined; }

    //  Validate Input
    const valid = await yup.string().isValid(input, { strict: true });
    if (!valid) { this.failValidation(input); }
    return input;
  }

  public async getElasticSchema() {
    return { type: 'keyword' };
  }
}
