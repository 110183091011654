import { deserializeSDT, DTArray, DTBoolean, DTKeyword, DTObject, DTSDT, DTText, SDTObject, DTAny } from "davel";
import { serializedTMObjectSchemaProperties, TMObject, tmObjectSchemaProperties } from "./tm-core";
import { AllTMSDTs } from "./types";
import { HaborSDK } from "../sdks";

// //  TODO:  Implement visibility once the need is more critical.
// export enum NounPropertyVisibility {
//   Protected = 'protected',  //  Only visible to sub-classes and the current class, NOT external entities.
//   Private = 'private',      //  Only visible to the current class.
//   Public = 'public'         //  Visible to everything.
// }

export enum NounPropertyModifier {
  // Final = 'final',    //  TODO:  Cannot be overridden by a sub-class.
  // Static = 'static',  //  TODO:  Defines a class variable.
  // Constant = 'constant',  //  TODO:  Defines a constant variable.  It's value cannot change after its declaration.
  // Required = 'required'   //  Defines a required variable.  NOTE: The 'required' option of the type indicates whether or not null / undefined will be a permitted value.  This flag determines whether a value is required at all.  Hmm.. Maybe the DT can handle this.
}

//  TODO:  Incorporate class variables into function variable resolution.
export interface NounProperty {
  name: string;
  description?: string;
  type?: AllTMSDTs;  //  NOTE:  Optional when a parent type is already defined for the same property name.
  static?: boolean;
  // modifiers?: NounPropertyModifier[];
  // visibility: NounPropertyVisibility;
  // manger?: string;
  // managerOptions: any;
  value?: any;
}

//  TODO-IMPORTANT:  Eventually move to a model where we have several different property types.  For example:  "Primitive", and "Managed".
//                   A "Managed" propety has a function for CRUDS operations.  Eventually, even make the default "Primitive" property a "Managed" property... Kind of like an endpoint.
export const nounPropertySchema = new DTObject({
  extensible: false,
  properties: {
    name: new DTKeyword({ required: true }),
    description: new DTText({ required: false }),
    type: new DTSDT({ required: false }),
    static: new DTBoolean({ required: false }),
    // modifiers: new DTArray({
    //   required: false,
    //   itemType: new DTOption({
    //     required: true,
    //     options: Object.keys(NounPropertyModifier)
    //   })
    // }),
    // visibility: new DTOption({
    //   required: true,
    //   options: Object.keys(NounPropertyVisibility)
    // }),
    // manager: new DTKeyword({ required: false }),  //  Name of the registered manager for this property.
    value: new DTAny({ required: false }) //  TODO:  Ideally, the type of this field would match the 'type' field.
  }
});

export const serializedNounPropertySchema: SDTObject = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    name: { type: "keyword", required: true },
    description: { type: "text", required: false },
    type: { type: "sdt", required: false },
    static: { type: "boolean", required: false },
    // modifiers: {
    //   type: "array",
    //   required: false,
    //   itemType: {
    //     type: "option",
    //     required: true,
    //     options: Object.keys(NounPropertyModifier)
    //   }
    // },
    // visibility: {
    //   type: "option",
    //   required: true,
    //   options: Object.keys(NounPropertyVisibility)
    // },
    // manager: { type: "keyword", required: false },
    value: { type: "any", required: false } //  TODO:  Ideally, the type of this field would match the 'type' field.
  }
};

//  Noun TypeScript
export interface NounInternal extends Noun, TMObject {}

export interface SerializedNounInternal extends Pick<NounInternal, Exclude<keyof NounInternal, 'properties'>> {
  properties: string;
}

export const serializeNoun = (noun: NounInternal): SerializedNounInternal => {
  return { ...noun, properties: JSON.stringify(noun.properties) };
};

export const deserializeNoun = (serNoun: SerializedNounInternal): NounInternal => {
  return { ...serNoun, properties: JSON.parse(serNoun.properties) };
};

export interface Noun {
  id: string;
  name: string;
  description: string;
  inherits?: string[]; //  List of Noun IDs
  properties: { [name: string]: NounProperty };
  abstract?: boolean;
  // plugin?: string;
}

//  DT and SDT Noun Properties
export const nounSchemaObjectProperties = {
  id: new DTKeyword({ required: true }),
  name: new DTKeyword({ required: true }),
  description: new DTText({ required: true }),
  inherits: new DTArray({
    itemType: new DTKeyword({ required: true })
  }),
  properties: new DTObject({ extensible: true, itemType: nounPropertySchema }),
  abstract: new DTBoolean({ required: false }),
  // plugin: new HDTDependency({ required: false, nounId: 'userplugin' })  //  TODO:  Eventually enforce a valid Plugin, maybe use the "noun" type?
};

export const serializedNounSchemaObjectProperties = {
  id: { type: "keyword", required: true },
  name: { type: "keyword", required: true },
  description: { type: "text", required: true },
  inherits: { type: "array", itemType: { type: "keyword", required: true } },
  properties: { type: "object", extensible: true, itemType: serializedNounPropertySchema },
  abstract: { type: "boolean", required: false },
  // plugin: { type: "dependency", nounId: 'userplugin', required: false }
};

//  DT Nouns
export const nounSchema = new DTObject({
  required: true,
  extensible: false,
  properties: nounSchemaObjectProperties
});
export const nounInternalSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...tmObjectSchemaProperties,
    ...nounSchemaObjectProperties
  }
});

export const serializedNounInternalSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...tmObjectSchemaProperties,
    ...nounSchemaObjectProperties,
    properties: new DTText({ required: true }),
  }
});

//  SDT Nouns
export const nounSerializedSchema: SDTObject = {
  type: "object",
  required: true,
  extensible: false,
  properties: {
    ...serializedNounSchemaObjectProperties
  }
};

export const nounInternalSerializedSchema: SDTObject = {
  type: "object",
  required: true,
  extensible: false,
  properties: {
    ...serializedTMObjectSchemaProperties,
    ...serializedNounSchemaObjectProperties
  }
};

export const serializedNounInternalSerializedSchema: SDTObject = {
  type: "object",
  required: true,
  extensible: false,
  properties: {
    ...serializedTMObjectSchemaProperties,
    ...serializedNounSchemaObjectProperties,
    properties: { type: "text" }
  }
};

export const getInstanceSchemaFromProperties = async (properties: {[key: string]: NounProperty }): Promise<SDTObject> => {
  const objProps: { [key: string]: AllTMSDTs } = {};
  Object.keys(properties).forEach((key: string) => {
    const property = properties[key];
    if (!property.static) {
      objProps[key] = property.type;
    }
  });

  //  Create the SDTObject
  const propertiesSerializedSchema: SDTObject = {
    type: "object",
    required: true,
    extensible: true,
    properties: objProps
  };

  //  Validate the SDTObject
  await deserializeSDT(propertiesSerializedSchema);
  return propertiesSerializedSchema;
};

// export const getInstanceSchema = async (nounInternal: NounInternal, habor: HaborSDK, token: string): Promise<SDTObject> => {

//   //  Get the Parent Properties
//   //  NOTE:  FOR NOW, higher indices have priority.
//   let properties: { [key: string]: NounProperty } = {};
//   for (const parent of nounInternal.inherits) {
//     const parentNoun = await habor.retrieveNoun(parent, token);
//     properties = { ...properties, ...parentNoun.properties };
//   }

//   //  Add the current Noun
//   properties = { ...properties, ...nounInternal.properties };

//   //  Remove Static Properties
//   const objProps: { [key: string]: AllTMSDTs } = {};
//   Object.keys(properties).forEach((key: string) => {
//     const property = properties[key];
//     if (!property.static) {
//       objProps[key] = property.type;
//     }
//   });

//   //  Create the SDTObject
//   const propertiesSerializedSchema: SDTObject = {
//     type: "object",
//     required: true,
//     extensible: false,
//     properties: objProps
//   };

//   //  Validate the SDTObject
//   await deserializeSDT(propertiesSerializedSchema);  //  NOTE:  Remove this for a possible performance boost.
//   return propertiesSerializedSchema;
// };
