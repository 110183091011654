import { CorePluginClass, HaliaStack } from "halia";
import { uniqueId } from "lodash";
import * as React from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import { HOCRegister, registerChildComponent, registerHOC, registerHOCRegister, registerOnMount, removeHOC } from "./hoc-register";

export class HaliaComponentPlugin extends CorePluginClass {

  public static details = {
    name: "Halia Component Plugin",
    description: "Plugin to Produce a Halia Component",
    dependencies: [],
    id: "haliaComponentPlugin"
  };

  protected component?: React.FunctionComponent | typeof React.Component;

  // protected component: React.FunctionComponent | typeof React.Component = () => <Text>Default Component</Text>;

  //  Make a Unique ID
  //  TODO:  Make sure it's sufficiently unique.. maybe a GUID, or attach to another thing? Hmm...
  protected id = "halia-plugin-" + uniqueId();

  public registerOnMount = (func: any) => registerOnMount(this.id, func);
  public registerHOC = (hoc: any) => registerHOC(this.id, hoc);
  public removeHOC = (hoc: any) => removeHOC(this.id, hoc);
  public registerChildComponent = (child: any) => registerChildComponent(this.id, child);

  public getComponent = () => this.component;

  public install = (program: any) => {

    registerHOCRegister(this.id);

    this.component = () => <HOCRegister id={this.id} />;

    return this;
  }
}

export class TestPlugin extends CorePluginClass {

  public static details = {
    name: "Test Plugin",
    description: "Plugin to Test Halia Thing",
    dependencies: [HaliaComponentPlugin.details.id],
    id: "testPlugin"
  };

  public install = (program: any, { haliaComponentPlugin }: { haliaComponentPlugin: HaliaComponentPlugin }) => {
    haliaComponentPlugin.registerChildComponent(() => <Text>PLUGIN TEST!!!</Text>);
    return this;
  }
}

//  TODO:  Instead of passing the class pass an ID.
//  TODO:  Support instances.

export interface PluginConfig {
  plugin: typeof CorePluginClass | undefined;
  options: any;
}

export interface HaliaComponentProps<T> {
  plugins: PluginConfig[];
  props: T;
}
interface HaliaComponentState {
  component?: React.FunctionComponent | typeof React.Component;
  err?: any;
}
export class HaliaComponent<T> extends React.Component<HaliaComponentProps<T>, HaliaComponentState> {
  protected prevComponent;
  constructor(props: HaliaComponentProps<T>) {
    super(props);
    this.state = {};
  }

  public componentDidMount = async () => {
    await this.buildStack(this.props);
  }

  public componentWillReceiveProps = async newProps => {
    await this.buildStack(newProps);
  }

  public buildStack = async (props: HaliaComponentProps<T>) => {
    //  TODO:  Shold the haliaComponentPlugin be like.. automatically coupled? Hmmm... idk.. I DO think MAYBE we can have a generic ENCODING or OUTPUT produced from the stack hmmm...maybee...  MAYBE we can ADD to the output and shit too hmm...

    //  TODO:  Add a Halia Config
    //  CONSIDER:  We COULD just instantiate the plugins when BUILDING... WHY should we do it when REGISTERING? Hmm... interesting.  HOW much verification shold we do whle registering? Hmm I THIN it's FINE to registe rhm!  EVEN when we don't have lal depeneices , it's a SUGGETION.. an INTENT kjlaffd !

    try {

      //  Duplicate Plugins for Mutation
      const newPluginConfigs = [...props.plugins];

      //  Add HaliaComponentPlugin as the Base Plugin if it's not already there.
      if (!newPluginConfigs.find(config => config.plugin.details.id === HaliaComponentPlugin.details.id)) {
        newPluginConfigs.push({ plugin: HaliaComponentPlugin, options: {} });
      }

      //  Build the Stack
      const stack = new HaliaStack();
      newPluginConfigs.forEach(config => {
        // if (!plugin) { alert("MISSING PLUGIN: " + plugin) }
        stack.register(config.plugin, config.options);
      });
      const result = await stack.build();

      //  Get the Component from the HaliaComponentPlugin
      const haliaComopnentPluginNode = result.find(p => p.id === HaliaComponentPlugin.details.id);
      if (!haliaComopnentPluginNode) { throw new Error(`No 'Halia Component Plugin' was registered.`); }
      const haliaComopnentPlugin = haliaComopnentPluginNode?.original.plugin as HaliaComponentPlugin;
      const component = haliaComopnentPlugin.getComponent();
      this.setState({ component, err: undefined });
    } catch (err) {
      // alert(err)
      this.setState({ err });
    }
  }

  public render = () => {
    const { component: Component, err } = this.state;
    // if (Component === this.prevComponent) { return }

    if (!Component && !err) { return <ActivityIndicator />; }

    if (!Component && err) { return <Text style={{ marginTop: 200, color: 'black' }}>An Error Occurred While Loading the App: {JSON.stringify(err)} {JSON.stringify(err.message)}</Text>; }

    //  TS Guard
    if (!Component) { return null; }

    // return <Text>HALIA COMPONENT</Text>
    //  NOTE:  HAHA NICE!  If I DON'T add the KEY here then EVEN when we change the STATE, the React REndnerer doesn't think the component has changed, becaus the name stayed and the props stayed... SO I guess we make sure to use a new key when we render this? hmm..
    //  TODO:  MAYBE change the key on re-build only!
    //  CONCERN:  Actually this doesn't seem to work either idfk...
    //  TODO-NEXT:  Figure out WHY I can't change the plugin tree... it's not letting me add even the blank Orchestrator. i THINK it's because it's re-registeirng with the React Navigation.. part of the reason I DON'T like having state outside the comonent tree.. when you unmount it leaves state...   KINDA makes me just want to do it myself. hmm... Leet it live in the componet BUT log it globally? Hmm...
    // alert("RENDERING")
    return (
      <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Component {...this.props.props} />
      </View>
    );
  }
}
