import { RGBAColor, RGBAColorSchema, RGBAColorSerializedSchema } from "./color";
import { Noun } from "./noun";

/**
 * Base Class for all Styles
 */
export interface ObjectStyle {
  iconType: string;
  iconName: string;
  color: RGBAColor;
}

export const ObjectStyleNoun: Noun = {
  id: "objectstyle",
  name: "Object Style",
  description: "Holds the Basic Style for Platform Objects",
  properties: {
    //  TODO:  Enforce types for "iconType" and "iconName"?  MAYBE create a new "Icon" type???
    iconType: {
      name: "iconType",
      type: { type: "keyword", required: true }
    },
    iconName: {
      name: "iconName",
      type: { type: "keyword", required: true }
    },
    color: {
      name: "color",
      type: RGBAColorSerializedSchema
    }
  }
};
