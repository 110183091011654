/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

import { DTOptions, DT, deserializeSDT } from "davel";
import { HSDTType, HSDTTypeSchema, AllTMSDTs } from "../models/types";

//  NOTE:  This is the Typescript Type for the CustomType ENTITY, which is where we store the type details referenced from a HDTCustomType.
export interface CustomType {
  name: string;
  description?: string;
  type: AllTMSDTs;
}

interface HDTCustomTypeOptions extends DTOptions {
  typeId: string;
}

export class HDTCustomType extends DT<string, any> {
  public name = "Custom";
  constructor(public options: HDTCustomTypeOptions, protected metadata?: any) {
    super(options);
  }

  public async validate(value: any): Promise<string | undefined> {

    //  Super Validation
    value = await super.validate(value);

    //  Handle Undefined
    if (value === undefined) { return undefined; }

    //  TODO-IMPORTANT:  Support isomorphic HaborSDK and centralize the type logic in HaborSDK.
    //  NOTE:  Until we have isomorphic HaborSDK, just pass the value through on the UI without validation.

    //  Validate Params
    return value;
  }
}


export const HSDTCustomTypeParser = async (sdt: HSDTType, metadata?: any): Promise<HDTCustomType> => {

  //  Validate
  const validatedSDT = await HSDTTypeSchema.validate(sdt);

  const serializedTypeCopy: any = { ...validatedSDT };
  delete serializedTypeCopy["type"];
  const hTypeCustomTypeOptions: HDTCustomTypeOptions = { ...serializedTypeCopy };

  //  Create the HTypeCustomType
  const hTypeCustomType = new HDTCustomType(hTypeCustomTypeOptions, metadata);
  return hTypeCustomType;
};
