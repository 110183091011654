import { SDTAnyParser, SDTArrayParser, SDTBooleanParser, SDTDateParser, SDTKeywordParser, SDTNumberParser, SDTObjectParser, SDTOptionParser, SDTSDTParser, SDTTextParser } from './parsers';
import { SDTDeserializer } from './parser-core';
import { SDTBinaryParser } from './parsers/binary';

export class TypeSpace {
  public typeToParser: { [type: string]: SDTDeserializer } = {
    'keyword': SDTKeywordParser,
    'text': SDTTextParser,
    'number': SDTNumberParser,
    'object': SDTObjectParser,
    'boolean': SDTBooleanParser,
    'date': SDTDateParser,
    'option': SDTOptionParser,
    'array': SDTArrayParser,
    'sdt': SDTSDTParser,
    'any': SDTAnyParser,
    'binary': SDTBinaryParser
  };
}

//  TODO:  Yes I DON'T believe we need this "Typespace" thing.  It's just a way to avoid injection of a singleton so we can have MULTIPLE "instances" known to Davel.  Instead, I'd prefer to keep Davel simple AND leverage existing JS instance tools to make a class based instance and inject? Hm!
//  TODO:  Consider passing the TypeSpace class instance to the types to avoid a global type selection.
export let selectedTypespace = new TypeSpace();
export const selectTypeSpace = (typespace: TypeSpace) => {
  selectedTypespace = typespace;
};