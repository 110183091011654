import { HDTRelationshipValue } from "../types";
import { EntityType } from "./identifiers";
import { namedObject, NamedObject, namedObjectSerializedSchema } from "./named-object";
import { Noun } from "./noun";
import { PageNoun } from "./page";

export interface Menu extends NamedObject {
  pages: HDTRelationshipValue[];
}

export const menuNoun: Noun = {
  id: "menu",
  name: "Menu",
  description: "A Workspace Menu",
  inherits: [namedObject.id],
  properties: {
    pages: {
      name: "pages",
      description: "Menu Pages",
      type: {
        type: "array",
        itemType: { type: "relationship", nounIds: [{ nounId: PageNoun.id, type: EntityType.Noun }], allowMany: false } as any
      }
    }
  }
};

export const menuSerializedSchema = {
  ...namedObjectSerializedSchema,
  pages: {
    type: "array",
    itemType: { type: "relationship", nounIds: [{ nounId: PageNoun.id, type: EntityType.Noun }], allowMany: false } as any
  }
};


//  TODO:  Again, consider abstracting the concept of default selection WITH Space inheritance??
//  TODO:  How can we FORCE one per Space AND prevent from going to Children?  MAYBE we don't... maybe it can be OVER-RIDDEN by children though?
//  TODO:  Perhaps change this to "Selected Menu"?  I SUPPOSE there can CURRENTLY be only one per space???
export interface DefaultMenuSelection {
  menu: HDTRelationshipValue;
}

export const DefaultMenuSelectionNoun: Noun = {
  id: "defaultmenuselection",
  name: "Default Menu Selection",
  description: "Selects a Menu for a Space",
  inherits: [],
  properties: {
    menu: {
      name: "menu",
      type: { type: "relationship", nounIds: [{ nounId: menuNoun.id, type: EntityType.Noun }], allowMany: false }
    }
  }
};