import * as yup from 'yup';
import { SDT, SDTSchemaProperties, SDTSerializedSchemaProperties } from '../parser-core';
import { DTKeyword, DTKeywordOptions, DTObject, DTOption } from '../types';
import { SDTObject } from './object';
import { SDTYup } from '../util';

//  TODO-IMPORTANT:  The distinction between keyword and text exists at an abstraction level that Davel should not be aware of.  In fact, we should be removing "elasticSchema" concepts entirely and map the DTs to Elastic Schemas outside of Davel... Maybe they don't even map 1-1 or maybe there are several layers of types before it hits the DB.  So, we do need to get that info at some level, possibly from the user, or learned, but it shouldn't be encoded here.
export interface SDTKeyword extends SDT {
  type: 'keyword';
}

const SDTKeywordYup = SDTYup.shape({
  type: yup.string().required().matches(/keyword/),
});

export async function SDTKeywordParser<MetadataType = any> (serialized: SDTKeyword, metadata?: MetadataType) {

  //  Validate
  const valid = SDTKeywordYup.validateSync(serialized);
  if (!valid) { throw new Error(`Keyword schema validation failed.`); }

  const serializedCopy: any = { ...serialized };
  delete serializedCopy['type'];
  const stringOptions: DTKeywordOptions = { ...serializedCopy };

  //  Create the DTKeyword
  const dtKeyword = new DTKeyword(stringOptions, metadata);
  return dtKeyword;
}

//  Schema to Validate an Keyword SDT
export const SDTKeywordSerializedSchema: SDTObject = {
  type: 'object',
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: 'option', options: ['keyword'] }
  }
};

export const SDTKeywordSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['keyword'] })
  }
});
