import * as yup from 'yup';
import { DT, DTOptions } from '../type-core';
import { SDTYup } from '../util';

export interface DTAnyOptions extends DTOptions {}

export type DTAnyValue = any;

export class DTAny<MetadataType = any> extends DT<any, MetadataType> {

  public name = 'Any';

  constructor(protected options: DTAnyOptions = {}, protected metadata?: MetadataType) {
    super(options, metadata);
  }

  //  TODO:  Should we be doing ANY conversion? Hmmm MAYBE we should?  Maybe support a "transform" step?
  public async validate(input: DTAnyValue): Promise<any | undefined> {

    //  Super Validation
    input = await super.validate(input);

    //  Handle Undefined
    if (input === undefined) { return undefined; }

    return input;
  }

  //  TODO-CRITICAL:  Elastic will not take any property type... Consider stringifying the input and then storing as a text field?
  public async getElasticSchema() {
    return {
      type: 'text'
    };
  }
}
