import { TMObject, tmObjectSchemaProperties, serializedTMObjectSchemaProperties } from "./tm-core";
import { DTKeyword, DTObject, SDTObject, DTText } from "davel";
import { Transaction, serializedTransactionSchema } from "./transaction";

//  TODO:  Eventually this should probably be 'InstanceCreateParams'.  The problem is that Habor currently expects 'nounId' to be passed with the instance and stored, but this is un-necessary and even dangerous when updating the instance.
export interface Instance<T = any> {
  nounId: string;
  id?: string;  //  Optional ID
  //  TODO:  Consider a "System" namespace (with IDs unique to the system?  MAYBE embedded namespaces?)
  payload: T;  //  Instance of the Noun (which is an instance of the schema provided on the Noun)
}

export const instanceSchemaProperties = {
  nounId: new DTKeyword({ required: true }),
  id: new DTKeyword({ required: false }),
  payload: new DTObject({ required: true, extensible: true })
};

export const instanceElasticSchemaProperties = {
  nounId: new DTKeyword({ required: true }),
  id: new DTKeyword({ required: false }),
  payload: new DTText({ required: true })  //  NOTE:  Notice how this is stored as "Text".... do we want this?
};

export const serializedInstanceSchemaProperties = {
  nounId: { type: "keyword", required: true },
  payload: { type: "object", required: true, extensible: true }
};

export const instanceSchema = new DTObject({
  extensible: false,
  required: true,
  properties: {
    ...instanceSchemaProperties
  }
});

export const serializedInstanceSchema = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    ...serializedInstanceSchemaProperties
  }
};

export interface InstanceInternal<T = any> extends TMObject, Instance<T> {
  id: string;
}

export interface InstanceElastic extends TMObject {
  nounId: string;
  payload: string;
}

export const instanceElasticSchema = new DTObject({
  extensible: false,
  required: true,
  properties: {
    ...instanceElasticSchemaProperties,
    ...tmObjectSchemaProperties
  }
});

export const instanceInternalSchema = new DTObject({
  extensible: false,
  required: true,
  properties: {
    ...instanceSchemaProperties,
    ...tmObjectSchemaProperties
  }
});

export const serializedInstanceInternalSchema = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    ...serializedInstanceSchemaProperties,
    ...serializedTMObjectSchemaProperties
  }
};

export interface InstanceResult<T = any> {
  instance: InstanceInternal<T>;
  tx: Transaction;
}

export const serializedInstanceResultSchema: SDTObject = {
  type: "object",
  properties: {
    instance: serializedInstanceInternalSchema,
    tx: serializedTransactionSchema,
    onInitResult: { type: "any" }
  }
};