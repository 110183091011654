import * as yup from 'yup';
import { deserializeSDT, SDT, SDTSchemaProperties, SDTSerializedSchemaProperties } from '../parser-core';
import { DT } from '../type-core';
import { DTArray, DTArrayOptions, DTObject, DTOption, DTSDT } from '../types';
import { AllDTTypeArray, SDTYup } from '../util';
import { SDTObject } from './object';

export interface SDTArray extends SDT {
  type: 'array';
  itemType: SDT;
}

const SDTArrayYup = SDTYup.shape({
  type: yup.string().required().matches(/array/),
  itemType: yup.object() //  TODO:  Add constraints to this!  Yup doesn't seem to support 'pattern' match on objects.
});

export async function SDTArrayParser<MetadataType = any> (serialized: SDTArray, metadata?: MetadataType) {

  //  Validate
  const valid = SDTArrayYup.validateSync(serialized);
  if (!valid) { throw new Error(`Array schema validation failed.`); }

  //  Create the Options
  const serializedCopy: any = { ...serialized };
  let itemType: DT<any>;
  try {
    itemType = await deserializeSDT(serialized.itemType, metadata);
  } catch (err) {
    throw new Error(`Failed to deserialize the 'itemType' SDT:\n\n${ itemType }\n\n${ err }`);
  }

  delete serializedCopy['type'];
  delete serializedCopy['itemType'];
  const arrayOptions: DTArrayOptions<any> = { ...serializedCopy, itemType };

  //  Create the DTObject
  const dtArray = new DTArray(arrayOptions, metadata);
  return dtArray;
}

//  Schema to Validate an Array SDT
export const SDTArraySerializedSchema: SDTObject = {
  type: 'object',
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: 'option', options: ['array'] },
    itemType: { type: 'sdt' }
  }
};

export const SDTArraySchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['array'] }),
    itemType: new DTSDT()
  }
});
