import * as yup from 'yup';
import { SDT, SDTSchemaProperties, SDTSerializedSchemaProperties } from '../parser-core';
import { DTBoolean, DTBooleanOptions, DTObject, DTOption } from '../types';
import { SDTObject } from './object';
import { SDTYup } from '../util';

export interface SDTBoolean extends SDT {
  type: 'boolean';
}

const SDTBooleanYup = SDTYup.shape({
  type: yup.string().required().matches(/boolean/), //  TODO: Does this need to be changed to valid?
});

export async function SDTBooleanParser<MetadataType = any> (serialized: SDTBoolean, metadata?: MetadataType) {

  //  Validate
  const valid = SDTBooleanYup.validateSync(serialized);
  if (!valid) { throw new Error(`Boolean schema validation failed`); }

  const serializedCopy: any = { ...serialized };
  delete serializedCopy['type'];
  const booleanOptions: DTBooleanOptions = { ...serializedCopy };

  //  Create the DTBoolean
  const dtBoolean = new DTBoolean(booleanOptions, metadata);
  return dtBoolean;
}


//  Schema to Validate an Boolean SDT
export const SDTBooleanSerializedSchema: SDTObject = {
  type: 'object',
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: 'option', options: ['boolean'] }
  }
};

export const SDTBooleanSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['boolean'] })
  }
});
