import * as yup from 'yup';
import { DT, DTOptions } from '../type-core';

export interface DTObjectOptions<ItemType = any, MetadataType = any> extends DTOptions {
  properties?: { [name: string]: DT<any, any> };
  extensible?: boolean;  //  Allow additional?  (false by default)
  itemType?: DT<ItemType, MetadataType>;
}

//  TODO:  Consider making new Typescript type aliases for all the Davel types.
//         This would clear up some confusion when: new DTObject<string> can refer to multiple suitable Davel types (Text, Keyword).
//         Actually, consider just removing these template variables...
export class DTObject<ItemType = any, MetadataType = any> extends DT<any, MetadataType> {
  public name = 'Object';

  constructor(public options: DTObjectOptions<ItemType, MetadataType> = {}, protected metadata?: MetadataType) {
    //  TODO:  Even though we're using Typescript, do validation on the options.  This applies to all types.
    super(options);
    const { properties, extensible, itemType } = options;
    this.options.properties = properties ? properties : {};
    this.options.extensible = extensible ? extensible : false;
    this.options.itemType = itemType ? itemType : undefined;
  }

  public async validate(input: any): Promise<any | undefined> {

    //  Super Validation
    input = await super.validate(input);

    //  Handle Undefined
    if (input === undefined) { return undefined; }

    //  Validate Input
    const valid = await yup.object().isValid(input);
    if (!valid) { this.failValidation(input); }

    //  Unpack the  Options
    const { properties, extensible, itemType } = this.options;

    //  Get the Object Property Names
    const typedPropNames = Object.keys(properties);

    //  Initialize the "Validated" Object
    const validatedProps: any = {};

    //  We have two objects:  "properties" (DTs) and "input" (Values).
    //  Iterate through the types and validate the corresponding value.
    for (let i = 0; i < typedPropNames.length; i++) {
      const propName = typedPropNames[i];
      const propDT = properties[propName];
      const propValue = input[propName];
      try {
        const validatedValue = await propDT.validate(propValue);
        validatedProps[propName] = validatedValue;
      } catch (err) {
        this.failValidation(input, `Validation failed for property '${ propName }' with value '${ propValue }'.  The property should be of type '${ propDT.name }'.  Here's the validation error: \n\n'${ err }`);
      }
    }

    //  Check if there are any values NOT present in the type list.
    //  If so, only allow this if the object is marked as "extensible".
    const inputPropNames = Object.keys(input);
    for (let i = 0; i < inputPropNames.length; i++) {
      const propName = inputPropNames[i];
      const propDT = properties[propName];
      const propValue = input[propName];
      if (!propDT) {
        if (extensible) {
          //  Validate the extra item if "itemType" is set.
          if (itemType) {
            try {
              await itemType.validate(propValue);
            } catch (err) {
              this.failValidation(input, `Validation failed for property '${ propName }' because the provided value:\n\n${ propValue }\n\nfailed validation for the provided extensible object 'itemType'.  The property should be of type '${ propDT.name }'.  Here's the validation error: \n\n'${ err }`);
            }
          }
          validatedProps[propName] = propValue;
        } else {
          this.failValidation(input, `Validation failed for property '${ propName }' because this key is not defined on the schema, and the object is not set as 'extensible'.  Please either make this object extensible or remove the '${ propName }' key.`);
        }
      }
    }
    return validatedProps;
  }

  public async getElasticSchema(topLevel: boolean) {

    //  Unpack Options
    const { properties: optionProperties, extensible } = this.options;

    //  Generate the Property Schemas
    const properties: any = {};
    for (const name in optionProperties) {
      const propDT = optionProperties[name];
      const childSchema = await propDT.getElasticSchema(false);
      properties[name] = childSchema;
    }

    //  Construct the Elastic Properties
    const elasticProperties = Object.keys(properties).length ? { properties } : {};

    //  Determine 'dynamic' setting
    const dynamic = extensible ? 'true' : 'strict';

    //  A top level document does not need to be nested, it's at the root of the index.
    if (topLevel) {
      return {
        _doc: {
          dynamic,
          ...elasticProperties
        }
      };
    } else {
      //  If nested, wrap the object
      //  TODO:  Currently, we always use 'nested' in case this object is stored in an array.  In the future, only use 'nested' if the object is part of an array.
      return {
        type: 'nested',
        dynamic,
        ...elasticProperties
      };
    }
  }
}
