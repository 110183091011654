import { SDTObject } from "davel";

export interface Transaction {
  timestamp: string; //  ISO Formatted Date String
  id: string;
  hash: string;
  raw: any;
}

export const serializedTransactionSchema: SDTObject = {
  type: "object",
  required: true,
  extensible: false,
  properties: {
    timestamp: { type: "keyword" },
    id: { type: "keyword" },
    hash: { type: "keyword" },
    raw: { type: "object", extensible: true } //  TODO:  Create an "any" Davel Type.
  }
};
