import * as yup from 'yup';
import { SDT, SDTSchemaProperties, SDTSerializedSchemaProperties } from '../parser-core';
import { DTDate, DTDateOptions, DTObject, DTOption } from '../types';
import { SDTObject } from './object';
import { SDTYup } from '../util';

export interface SDTDate extends SDT {
  type: 'date';
}

const SDTDateYup = SDTYup.shape({
  type: yup.string().required().matches(/date/), //  TODO: Does this need to be changed to valid?
});

export async function SDTDateParser<MetadataType = any> (serialized: SDTDate, metadata?: MetadataType) {

  //  Validate
  const valid = SDTDateYup.validateSync(serialized);
  if (!valid) { throw new Error(`Date schema validation failed.`); }

  const serializedCopy: any = { ...serialized };
  delete serializedCopy['type'];
  const booleanOptions: DTDateOptions = { ...serializedCopy };

  //  Create the DTDate
  const dtDate = new DTDate(booleanOptions, metadata);
  return dtDate;
}


//  Schema to Validate an Date SDT
export const SDTDateSerializedSchema: SDTObject = {
  type: 'object',
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: 'option', options: ['date'] }
  }
};

export const SDTDateSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['date'] })
  }
});
