/**
 * Identifies a set of Habor Objects (Nouns, Instances, and Fields)
 *
 * RULES:
 * Each field has a parent instance, each instance has a parent noun, and each noun has the same parent 'root' object.
 * The 'root' object is added to the the 'default' list.
 * A node may not be listed more than once.
 * An unlisted node implicitly belongs to the same list as its closest listed parent.
 */

 export enum DefaultListing {
   Blacklist = 'blacklist',
   Whitelist = 'whitelist'
 }

export interface HSelection {
  default: DefaultListing;
  whitelist: string[];
  blacklist: string[];
}