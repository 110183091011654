import * as yup from 'yup';
import { DT, DTOptions } from '../type-core';

//
//  DTBinary:  An indexed text field for structured data.
//  REFERENCE:  https://www.elastic.co/guide/en/elasticsearch/reference/current/binary.hdtl
//

export interface DTBinaryOptions extends DTOptions {}

export class DTBinary<MetadataType = any> extends DT<string, MetadataType> {

  public name = 'Binary';

  constructor(public options: DTBinaryOptions = {}, protected metadata?: MetadataType) {
    super(options);
  }

  //  NOTE:  "input" shuold be a base 64 encoded string.
  //  TODO:  Consider decoding to ensure it's in the proper format.
  public async validate(input: string): Promise<string | undefined> {

    //  Super Validation
    input = await super.validate(input);

    //  Handle Undefined
    if (input === undefined) { return undefined; }

    //  Validate Input
    const valid = await yup.string().isValid(input, { strict: true });
    if (!valid) { this.failValidation(input); }
    return input;
  }

  public async getElasticSchema() {
    return { type: 'binary' };
  }
}
