/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

//  TODO-CRITICAL:  THIS FILE IS MODIFIED FROM HABOR.
//                  This makes it possible to validate a noun in the UI, BUT should it use the API?  That would cause a freeze... unless we have a standard API that uses HTTP when in the UI and runs locally in Habor!  I like this idea!  Either way, the things passed in the metadata will be a Habor SDK with the same interface!

//  CONSIDER:  This "type" can register a "LandingPad" with the system.  It doesn't have to actually affect the primitive...  But it MAY improve performance if we do that.  This is how the system knows we CAN attach primitive "Relationships" to an Entity.

import { deserializeSDT, DT, DTOptions, SDTObject } from "davel";
import { HSDTRelationship, InstanceID, instanceIdSchema, NounId, nounIdSchema } from "../models";

export interface HDTRelationshipValue {
  instanceIdList: InstanceID[];
}

export const HDTRelationshipValueSerializedSchema: SDTObject = {
  type: "object",
  extensible: false,
  properties: {
    instanceIdList: {
      type: "array",
      itemType: { ...instanceIdSchema }
    }
  }
};

//  TODO:  Consider the more general "Object Selection" pattern, where we have a Whitelist and a Blacklist instead of JUST a simple list of Nouns.  Then we CAN work toward more complex selection queries.
//  TODO-FUTURE:  Support undefined 'nounIds' to support ANY Noun... Currently this comes with at least 2 technical challenges.  One, we create a "Remote Property Declaration" on nouns in this list, so we need to know what they are... It seems dangerous to apply the declaration to EVERY Noun... Two, in the UI we need to SEARCH for instances to make our selection, and unbounded (by Noun) search is NOT currently supported.
export interface HDTRelationshipOptions extends DTOptions {
  allowMany?: boolean;  //  Defaults to 'true'.
  nounIds: NounId[];  //  Optionally accept from several Nouns.  If the array is empty, accept none.  If the property is undefined , EVENTUALLY consider accepting ANY.
}

export class HDTRelationship extends DT<HDTRelationshipValue, any> {
  public name = "Relationship";
  constructor(public options: HDTRelationshipOptions, metadata?: any) {
    super(options);
  }

  public async validate(hRelationship: HDTRelationshipValue): Promise<HDTRelationshipValue | undefined> {

    //  Super Validation
    hRelationship = await super.validate(hRelationship);

    //  Handle Undefined
    if (hRelationship === undefined) { return undefined; }

    //  Parse the Schdema
    const dt = await deserializeSDT(HDTRelationshipValueSerializedSchema);

    //  Validate Input
    hRelationship = await dt.validate(hRelationship);

    //  Unpack Params
    const { instanceIdList } = hRelationship;

    //  Unpack Options
    const { allowMany } = this.options;

    //  Check 'allowMany'
    if (!allowMany) {
      if (instanceIdList.length > 1) { throw new Error("Relationship validation failed.  Multiple instances specified while 'allowMany' is false."); }
    }

    //  Get the Context
    // const context = metadata.context ? metadata.context : undefined;

    // //  Validate that the provided Instances are valid
    // const matchQueries = instanceIdList.map(id => ({ match: { id } }));
    // const insts = await habor.instanceService.searchInstances({ nounId, search: { any: matchQueries} }, null, context);

    // //  Verify Length
    // if (insts.length != instanceIdList.length) { throw new Error("Relationship validation failed.  Could not find all of the provided instances."); }

    //  Validate Params
    return hRelationship;
  }

  public async getElasticSchema() {
    const dt = await deserializeSDT(HDTRelationshipValueSerializedSchema);
    const esSchema = await dt.getElasticSchema(false);
    return esSchema;
  }
}

export const HSDTRelationshipSerializedSchema: SDTObject = {
  type: "object",
  required: true,
  extensible: false,
  properties: {
    type: {
      type: "option",
      options: ["relationship"],
      required: true,
    },
    allowMany: {
      type: "boolean",
      required: false
    },
    nounIds: {
      type: "array",
      itemType: nounIdSchema,
      required: false
    }
  }
};

export const HSDTRelationshipParser = async (sdt: HSDTRelationship, metadata?: any): Promise<HDTRelationship> => {

  //  Deserialize Type
  const dt = await deserializeSDT(HSDTRelationshipSerializedSchema);

  //  Validate
  const validatedSDT = await dt.validate(sdt);

  const serializedTypeCopy: any = { ...validatedSDT };
  delete serializedTypeCopy["type"];
  const hTypeRelationshipOptions: HDTRelationshipOptions = { ...serializedTypeCopy };

  //  Create the HTypeRelationship
  const hTypeRelationship = new HDTRelationship(hTypeRelationshipOptions, metadata);
  return hTypeRelationship;
};
