import fetch from 'cross-fetch';
import * as _ from 'lodash';
import { isObject } from 'util';
import { EntityID, HPrimitiveParamMap, HRequest } from '../models';
import { Instance, InstanceInternal } from '../models/instance';
import { Noun, NounInternal } from '../models/noun';
import { RequestRecord } from '../models/request';
import { valueAtPath } from '../utils';

//  REFERENCE:  https://medium.com/cameron-nokes/4-common-mistakes-front-end-developers-make-when-using-fetch-1f974f9d1aa1

//  TODO:  Eventually make search universal and remove "Noun" vs "Instance" distinction?


//  REFERENCE:  https://stackoverflow.com/a/51365037
type RecursivePartial<T> = {
  [P in keyof T]?:
    T[P] extends (infer U)[] ? RecursivePartial<U>[] :
    T[P] extends (object | undefined) ? RecursivePartial<T[P]> :
    T[P];
};


export type Primitive = string | number | Date;

/**
 * I'm not sure how to express this in TS, but an ObjectPath has only ONE key per level.
 * TODO:  How can we enforce this with a Match clause and the recursive partial?
 */
export interface ObjectPath {
  [name: string]: ObjectPath | Primitive;
}

export type ArrayPath = string[];

/**
 * Converts an ObjectPath (which only 1 key per level) to an array of strings.
 * @param obj
 * @param startKey Optionally start the path from a particular key.
 */
export const getArrayPathFromObjPath = (obj: any, startKey?: string): string[] => {
  const newObj: ObjectPath = startKey ? obj[startKey] : obj;
  if (Object.keys(newObj).length > 1) { throw new Error("Cannot construct an object path from an object with multiple keys"); }
  const key = Object.keys(newObj)[0];
  const nestedObj = newObj[key];
  const path = isObject(nestedObj) ? getArrayPathFromObjPath(nestedObj) : [];
  return startKey ? [startKey, key, ...path] : [key, ...path];
};

export function processMatchTerm<T>(matchSearchTerm: MatchSearchTerm, objects: any[]): any[] {

  //  TODO:  Consider using an Array of Strings to select a path instead of a Nested Object.
  //  NOTE:  The "MatchSearchTerm" is an object with multiple keys allowed in the first layer.  Attached to each key is an "ObjectPath".  This is an Object where EVERY level has only a single key with either an ObjectPath or a Value.

  //  Validate
  const { match } = matchSearchTerm;
  if (!match) {
    throw Error("Cannot generate a Match search term with a non-match search term.");
  }

  //  Create a filtered Array
  const filteredArray = objects.filter(obj => {
    const matchKeys = Object.keys(match);

    //  NOTE:  Match Keys work like an "AND" Query...
    //  TODO:  Disable support for MULTIPLE Match keys... use an "AND" Query instead.
    for (const matchKey of matchKeys) {

      //  Construct a path for each key
      const path = getArrayPathFromObjPath(match, matchKey);

      //  Get the Value of the Match Term
      const queryValue = valueAtPath(match, path);

      //  Get the Value of the Object
      const objValue = valueAtPath(obj, path);

      //  Check Equivalence
      if (objValue != queryValue) {
        return false;
      }
    }
    return true;
  });

  return filteredArray;
}

export function processAnyTerm<T>(anySearchterm: AnySearchTerm<T>, objects: any[]): any[] {

  //  Validate
  const { any } = anySearchterm;
  if (!any) {
    throw Error("Cannot generate an 'Any' search term with a non-any search term.");
  }

  //  Process Child Terms
  const results = any.map(term => {
    return searchObjects(term, objects);
  });

  //  Combine Results (Set Union)
  return _.union(results);
}

export function processAllTerm<T>(allSearchTerm: AllSearchTerm<T>, objects: any[]): any[] {

  //  Validate
  const { all } = allSearchTerm;
  if (!all) {
    throw Error("Cannot generate an 'All' search term with a non-all search term.");
  }

  //  Process Child Terms
  const results = all.map(term => {
    return searchObjects(term, objects);
  });

  //  Combine Results (Set Intersection)
  return _.intersection(results);
}

//  TODO:  Create some form of interface to keep logic shared between DB searches and local search?  Almost like a Repo!  The interface DID change though, because locally, we need to put in values.  Maybe wrap this in a Class and initialize the class with values?
export function searchObjects<T>(searchTerm: SearchTerm<T>, objects: any[]): any {

  //  Handle Undefined (Return All)
  if (searchTerm == undefined) { return objects; }

  //  Declare Types
  const matchSearchTerm = searchTerm as MatchSearchTerm<T>;
  const allSearchTerm = searchTerm as AllSearchTerm<T>;
  const anySearchterm = searchTerm as AnySearchTerm<T>;

  //  CONSIDER:  Inject these??

  //  Check Match
  if (matchSearchTerm.match !== undefined) {
    return processMatchTerm<T>(matchSearchTerm, objects);
  }

  //  Check All
  if (allSearchTerm.all !== undefined) {
    return processAllTerm<T>(allSearchTerm, objects);
  }

  //  Check Any
  if (anySearchterm.any !== undefined) {
    return processAnyTerm<T>(anySearchterm, objects);
  }
}


export interface NounSearchParams<T = any> {
  search?: SearchTerm<T>;
  from?: number;  //  Defaults to 0
  size?: number;  //  Defaults to 50
}

export interface SearchTermBase {}

export interface RangeParams {
  gt?: string;
  gte?: string;
  lt?: string;
  lte?: string;
}

export type RangeMap = { [name: string]: RangeMap | RangeParams };

export interface RangeSearchTerm<T = any> extends SearchTermBase {
  range: RangeMap;  //  TODO:  Actually type with the T type.
}

export interface MatchSearchTerm<T = any> extends SearchTermBase {
  match: RecursivePartial<T>;  //  CONCERN:  This should lead down a single path, not multiple.
}

export type ExistsMap = { [name: string]: ExistsMap | boolean };
export interface ExistsSearchTerm<T = any> extends SearchTermBase {
  exists: ExistsMap;  //  TODO:  Actually type with the T type.
}

export interface NotSearchTerm<T = any> extends SearchTermBase {
  not: { [name: string]: any };
}


export interface AllSearchTerm<T = any> extends SearchTermBase {
  all: SearchTerm<T>[];
}

export interface AnySearchTerm<T = any> extends SearchTermBase {
  any: SearchTerm<T>[];
}

export type SearchTerm<T = any> = AnySearchTerm<T> | AllSearchTerm<T> | MatchSearchTerm<T> | ExistsSearchTerm<T> | NotSearchTerm<T> | RangeSearchTerm<T>;

//  IDEA:  Currently, if the set of values meeting a search condition changes, then the start / size specifiers may be off?  So, consider passing some sort of hash / indicator that a search set was invalidated?  Maybe this is OK.  The user MAY miss data, but they can refresh?
export interface InstanceSearchParams<T = any> {
  nounId: string;
  search?: SearchTerm<T>;
  from?: number;  //  Defaults to 0
  size?: number;  //  Defaults to 50
}

export class UnknownError {}
export class NotFoundError {}

export interface ReplayParams {
  runAs: string;
  timestamp: string;
}

export const processError = (res: Response) => {
  if (res.status === 404) { throw new NotFoundError(); }
  throw new UnknownError();
};

export class HaborSDK {
  constructor(public host: string) {}

  //  TODO:  Validate everything!
  //         Need to figure out how to handle validators that depend on Habor state... I think we should split it into two steps...
  //         The validation step and then a step to determine whether or not the primitive matches, THEN attempt to use it and throw an exception on failure...
  //         However, it would still be nice to add an "internalValidation" step or something... maybe have multiple levels of validation!?
  public async createNoun(noun: Noun, token: string): Promise<NounInternal> {
    const res = await fetch(`${this.host}/nouns`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(noun)
    });
    if (!res.ok) { processError(res); }
    const json = await res.json();
    return json;
  }

  //  TODO:  Remove "nounId", this is redundant because it's in the "noun" model.
  public async updateNoun(nounId: string, noun: Noun, token: string): Promise<NounInternal> {
    if (nounId == undefined) { throw new Error("Cannot update an instance without a valid instance ID."); }
    const res = await fetch(`${this.host}/nouns/${nounId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(noun)
    });
    if (!res.ok) { processError(res); }
    const json = await res.json();
    return json;
  }

  public async processRequest(req: HRequest, token: string): Promise<any> {
    const res = await fetch(`${this.host}/request`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(req)
    });
    if (!res.ok) { processError(res); }
    const json = await res.json();
    return json;
  }

  public async replayRequest(record: RequestRecord, token: string): Promise<any> {
    const res = await fetch(`${this.host}/replay`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(record)
    });
    if (!res.ok) { processError(res); }
    const json = await res.json();
    return json;
  }

  public async deleteNoun(nounId: string, token: string): Promise<void> {
    const res = await fetch(`${this.host}/nouns/${nounId}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
    });
    if (!res.ok) { processError(res); }
    if (res.status !== 200) {
      throw new Error('Failed to delete the noun.');
    }
  }

  public async searchNouns<T = any>(token: string, params?: NounSearchParams<T>): Promise<NounInternal[]> {
    const res = await fetch(`${this.host}/nouns/_search`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(params)
    });
    if (!res.ok) { processError(res); }
    const json = await res.json();
    return json;
  }

  public async retrieveNoun(nounId: string, token: string): Promise<NounInternal> {
    const res = await fetch(`${this.host}/nouns/${nounId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
    });
    if (!res.ok) { processError(res); }
    const json = await res.json();
    return json;
  }

  public async retrieveSerializedNoun(nounId: string, token: string, targetObjectId?: EntityID): Promise<NounInternal> {
    const res = await fetch(`${this.host}/nouns/${nounId}/serialized`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(targetObjectId)
    });
    if (!res.ok) { processError(res); }
    const json = await res.json();
    return json;
  }

  //  CONCERN:  Is the Input ALWAYS the same as the Output?  Consider "Token".  Does the token model violate the instance model, or vice versa?  Maybe we should support both input and output types?
  public async createInstance<Input = any, Output = Input>(instance: Instance<Input>, token: string): Promise<InstanceInternal<Output>> {
    const res = await fetch(`${this.host}/nouns/${instance.nounId}/instances`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(instance)
    });
    if (!res.ok) { processError(res); }
    const json = await res.json();
    return json;
  }

  public async retrieveInstance<T = any>(nounId: string, instanceId: string, token: string): Promise<InstanceInternal<T>> {
    const res = await fetch(`${this.host}/nouns/${nounId}/instances/${instanceId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
    });
    if (!res.ok) { processError(res); }
    const json = await res.json();
    return json;
  }

  public async searchInstances<T = any>(token: string, params: InstanceSearchParams<RecursivePartial<InstanceInternal<T>>>): Promise<InstanceInternal<T>[]> {
    const { nounId } = params;
    if (nounId) {
      const res = await fetch(`${this.host}/nouns/${nounId}/instances/_search`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-access-token': token },
        body: JSON.stringify(params)
      });
      if (!res.ok) { processError(res); }
      const json = await res.json();
      return json;
    } else {
      throw new Error('Global instance search is not currently supported.');
    }
  }

  //  TODO:  Remove "nounId", this is redundant because it's in the "noun" model.
  public async updateInstance<Input = any, Output = Input>(instanceId: string, instance: Instance<Input>, token: string): Promise<InstanceInternal<Output>> {
    if (instanceId == undefined) { throw new Error("Cannot update an instance without a valid instance ID."); }
    const res = await fetch(`${this.host}/nouns/${instance.nounId}/instances/${instanceId}/_update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(instance)
    });
    if (!res.ok) { processError(res); }
    const json = await res.json();
    return json;
  }

  public async deleteInstance(nounId: string, instanceId: string, token: string): Promise<void> {
    const res = await fetch(`${this.host}/nouns/${ nounId }/instances/${ instanceId }`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
    });
    if (!res.ok) { processError(res); }
    if (res.status !== 200) {
      throw new Error('Failed to delete the instance.');
    }
  }

  public async invokeMethod(nounId: string, instanceId: string, methodName: string, params: HPrimitiveParamMap, token: string): Promise<any> {
    const res = await fetch(`${this.host}/nouns/${nounId}/instances/${instanceId}/methods/${methodName}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-access-token': token },
      body: JSON.stringify(params)
    });
    if (!res.ok) { processError(res); }
    const json = await res.json();
    return json;
  }
}
