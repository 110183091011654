export enum AccessPermission {
  Any = "any",
  Owner = "owner"
}

export interface AccessPolicy {
  nounId: string;
  owner: string;
  permission: AccessPermission;
}

export interface InstanceAccessPolicy {
  nounId: string;
  instanceId: string;
  owner: string;
  permission: AccessPermission;
}
