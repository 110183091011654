import { SDT, SDTObject, SDTSerializedSchemaProperties, DTObject, SDTSchemaProperties, DTOption, DTKeyword } from "davel";

//  TODO:  Convert the grays to a number scale, like 1, 2, 3... instead of "almost..."
export enum Color {
  primary = '#787878',
  secondary = '#d245e8',
  danger = '#ff3258',
  warning = '#ffcf31',
  good = '#38d159',
  white = 'white',
  offWhite = '#EFEFEF',
  lighterGray = '#DDDDDD',
  lightGray = '#CCCCCC',
  almostMedGray = '#BBBBBB',
  medGray = '#999999',
  almostDarkGray = '#555555',
  darkGray = '#333333',
  black = 'black',
  transparent = 'rgba(0,0,0,0)'
}

//  REFERENCE:  https://casesandberg.github.io/react-color/#create-parent
export interface RGBAColor {
  a?: string;
  b: string;
  g: string;
  r: string;
}

//  NOTE:  We use the word "Schema" to denote any DTObject or SDTObject which matches a given pattern...  It IS true that this is an SDT for the Object type, but when we name a schema, we're naming regarding the object that the schema validates.
export const RGBAColorSerializedSchema: SDTObject = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    a: { type: "keyword", required: false },
    b: { type: "keyword", required: true },
    g: { type: "keyword", required: true },
    r: { type: "keyword", required: true },
  }
};

export const RGBAColorSchema: DTObject = new DTObject({
  extensible: false,
  required: true,
  properties: {
    a: new DTKeyword({ required: false }),
    b: new DTKeyword({ required: true }),
    g: new DTKeyword({ required: true }),
    r: new DTKeyword({ required: true }),
  }
});

export interface HSDTColor extends SDT {
  type: "color";
}

//  HSDT Color Schemas

//  NOTE:  SDT Explanation
//    Every SDT(X)Schema is a DTObject (which means it has options).  This DT will validate a serialized DT of the given type (using the provided options).  Each serialized DT is simply a POJO object encoding the options to be provided to the constructor of the DT.
//    For example, SDTCarSchema would be a DTObject.  In this case, "X" is "Car".  This DT object will match a JSON blob with the options required for a car type, a serialized type definition.  For example, a "Make" property.
//    Then, the corresponding value is an SDT which can be parsed to a DT and used to validate that the incoming car is indeed of that Make.
//    Every SDT(X)SerializedSchema is a serialized DT Object which encodes the options used to make the DT to validate the POJO of options used to make the desired type.

export const HSDTColorSerializedSchema: SDTObject = {
  type: "object",
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: "option", options: ["color"] }
  }
};

export const HSDTColorSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['color'] }),
  }
});
