import { RGBAColor, RGBAColorSerializedSchema } from "./color";
import { Noun } from "./noun";
import { namedObject, NamedObject } from "./named-object";
import { EntityType } from "./identifiers";
import { HDTRelationshipValue } from "../types";

//  NOTE:  In the future, we MAY want to let the user select the "Workspace Provider".  This essentially lets the user install a Workspace Handler which will be the skin of the Workspace.  This is the thing that will allow registration in certain points, etc... Once we have the other systems working, this should become easier!
export interface Page extends NamedObject {
  componentId?: string;
}

export const PageNoun: Noun = {
  id: "page",
  name: "Page Definition",
  description: "Defines a Page in a Workspace",
  inherits: [namedObject.id],
  properties: {
    componentId: {
      name: "componentId",
      description: "The component to render for this page.",
      type: { type: "keyword", required: false } //  TODO:  Replace with proper type.
    },
    //  NOTE:  This is what we call a "TrackMine" Primitive... So we have some hard-coded UI for now.  Non-Hessia Primitive objects should NOT have Style built-in.
  }
};

export interface DefaultPageSelection {
  page: HDTRelationshipValue;
}


//  CONSIDER:  This is interesting... we COULD have just used a relationship, but we instead opted for a new noun with a relationship FIELD.  Huh...  The field does ESSENTIALLY the same thing!  Very interesting... perhaps we SHOULD have just made a relationship?  I suppose we COULD consider getting rid of the relationship primitive type altogether?
//             ONE point is that we have a TYPE in Davel to handle this!  IF we just used a "Relationship", then the internal fields are just strings at the moment.  MAYBE we can use actual relationships.  The SAME logic applies to the "DefaultMenuSelection" Noun!
export const DefaultPageSelectionNoun: Noun = {
  id: "defaultpageselection",
  name: "Default Page Selection",
  description: "Selects a Page for a Space",
  inherits: [],
  properties: {
    page: {
      name: "page",
      type: { type: "relationship", nounIds: [{ nounId: PageNoun.id, type: EntityType.Noun }], allowMany: false }
    }
  }
};