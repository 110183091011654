import { NounInternal, Noun, Instance, InstanceInternal } from "./models";

export const constructQueryString = (obj: any): string => {
  const queryParams = Object.keys(obj).map((key: string) => {
    const value = obj[key];
    //  TODO:  Consider a less intrusive encoding to increase readability.
    const param = `${key}=${encodeURIComponent(value)}`;
    return param;
  });
  const queryString = queryParams.join('&');
  return queryString;
};

export const valueAtPath = (obj: any, path: string[]) => {
  if (obj === undefined) { return undefined; }
  let value = obj;
  for (const part of path) {
    value = value[part];
  }
  return value;
};

//  TODO:  Move these to SDK
export const nounFromInternal = (nounInternal: NounInternal): Noun => {
  const { id, name, description, properties, inherits, abstract } = nounInternal;
  const nounRes: Noun = { name, id, description, properties, inherits, abstract };
  return nounRes;
};

export const instanceFromInternal = (instanceInternal: InstanceInternal) => {
  const { nounId, payload } = instanceInternal;
  const instance: Instance = { nounId, payload };
  return instance;
};
