//  TODO-CRITICAL:  THIS FILE IS MODIFIED FROM HABOR.
//                  This makes it possible to validate a noun in the UI, BUT should it use the API?  That would cause a freeze... unless we have a standard API that uses HTTP when in the UI and runs locally in Habor!  I like this idea!  Either way, the things passed in the metadata will be a Habor SDK with the same interface!

import { DTOptions, DT, SDT, SDTYup } from "davel";
import * as yup from "yup";
import { TMSerializedNoun } from "../models";

//
//  TMNoun
//
interface TMNounOptions extends DTOptions {
  nounId: string;
  required?: boolean;
}

class TMNoun extends DT<string, any> {
  public name = "Noun";

  constructor(public options: TMNounOptions, protected metadata?: any) {
    super(options);
  }

  /**
   * Validates that the given instance is an instance of the given noun.
   */
  public async validate(instanceId: string): Promise<string> {
    const { required } = this.options;
    if (instanceId) {
      return instanceId;
    } else {
      if (required) {
        throw new Error("No instance ID provided for the required noun.");
      } else {
        return instanceId;
      }
    }
  }

  public async getElasticSchema() {
    return {
      type: "keyword" //  NOTE:  We store in an Elastic Keyword field so the relationship is searchable.
    };
  }
}


//
// TMNoun Serializer
//

//  TODO: Much of this is boilerplate that can be centralized and instead created using a function and Davel types.
//  Defines the Structure of the Noun Schema

//  Yup Structure of the Noun Schema
const TMSerializedNounJoi = SDTYup.shape({  //  TODO:  Remove this and extend from the SDT schema... This will currently fail with additional props like "required".
  type: yup.string().required().matches(/noun/),
  nounId: yup.string().required()
});

//  Parse JSON to a Noun Schema
export const TMSerializedNounParser = async (serialized: TMSerializedNoun, metadata?: any): Promise<TMNoun> => {

  //  Validate
  const valid = TMSerializedNounJoi.validateSync(serialized);
  if (!valid) { throw new Error(`Noun schema validation failed`); }

  const serializedTypeCopy: any = { ...serialized };
  delete serializedTypeCopy["type"];
  const nounOptions: TMNounOptions = { ...serializedTypeCopy };

  //  Create the TMNoun
  const tmNoun = new TMNoun(nounOptions, metadata);
  return tmNoun;
};

