import { DTOptions, DT, DTObject, SDT, DTOption } from "davel";
import { HUserFunction, HUserFunctionSchema, HSDTUserFunction } from "../models";

interface HDTUserFunctionOptions extends DTOptions {}

//  NOTE:  For a user to set a property on a Noun, they need to specify a valid SDT.  We need to interpret
//         that SDT as a function, NOT some other type.  This is why we need a new type instead of a
//         schema (which would be of type 'object' and treated as an object property instead of a method).

export class HDTUserFunction extends DT<HUserFunction, any> {
  public name = "Function";

  constructor(public options: HDTUserFunctionOptions, protected metadata?: any) {
    super(options);
  }

  public async validate(hUserFunction: HUserFunction): Promise<HUserFunction | undefined> {

    //  Super Validation
    hUserFunction = await super.validate(hUserFunction);

    //  Handle Undefined
    if (hUserFunction === undefined) { return undefined; }

    //  Validate Input
    hUserFunction = await HUserFunctionSchema.validate(hUserFunction);

    //  TODO:  Validate the statements!

    return hUserFunction;
  }

  public async getElasticSchema() {
    return HUserFunctionSchema.getElasticSchema(false);
  }
}


//
// HSDTUserFunction Serializer
//

const HSDTUserFunctionSchema  = new DTObject({
  required: true,
  extensible: true,  //  TODO:  Remove this and extend from the SDT schema.
  properties: {
    type: new DTOption({
      required: true,
      options: ["user-function"]
    })
  }
});


//  Parse JSON to a HUserFunctionType
export const HSDTUserFunctionParser = async (sdt: HSDTUserFunction, metadata?: any): Promise<HDTUserFunction> => {

  //  Validate
  const validatedSDT = await HSDTUserFunctionSchema.validate(sdt);

  const serializedTypeCopy: any = { ...validatedSDT };
  delete serializedTypeCopy["type"];
  const hTypeStatementOptions: HDTUserFunctionOptions = { ...serializedTypeCopy };

  //  Create the HTypeStatement
  const hTypeStatement = new HDTUserFunction(hTypeStatementOptions, metadata);
  return hTypeStatement;
};
