import { DTOptions, DT, DTObject, SDT, DTOption } from "davel";
import { HStatement, HStatementSchema, HSDTStatement } from "../models";

interface HDTStatementOptions extends DTOptions {}

//  NOTE:  We use a custom type instead of a schema to support custom validation of the function name.
//         This is because we want to make sure it matches a registered function.
//  TODO:  Consider switching to a Type once the more general "RemoteOption" type is available to perform the validation.
export class HDTStatement extends DT<HStatement, any> {
  public name = "Statement";
  constructor(public options: HDTStatementOptions, protected metadata?: any) {
    super(options);
  }

  /**
   * Validates that the given instance is a valid invocation.
   * NOTE:  Many invocations are asynchronous in terms of the underlying system, but they are synchronous (blocking)
   *        in terms of the Habor event loop.
   */
  public async validate(hStatement: HStatement): Promise<HStatement | undefined> {

    //  Super Validation
    hStatement = await super.validate(hStatement);

    //  Handle Undefined
    if (hStatement === undefined) { return undefined; }

    //  Validate Input
    hStatement = await HStatementSchema.validate(hStatement);

    //  Unpack Params
    const { functionName, params } = hStatement;

    //  Unpack Metadata
    const { habor, functionService } = this.metadata;

    //  Validate a function exists with the given name
    //  QUESTION:  Do we have a race condition here?  What if a user is registering and using a function at the same time.
    //             Do we NEED to validate that the function is registered here or can we defer this decision?  Worse, what if we
    //             validate here, assuming it will exist later... FOR NOW, lets assume functions are immutable.
    const func = await functionService.getFunction(functionName);

    //  Validate Params
    //  Make sure the underlying param type matches the expected param type.
    //  TODO-CRITICAL:  We need to validate the params to ensure the function will be called successfully.
    return hStatement;
  }

  public async getElasticSchema() {
    return {
      dynamic: "strict",
      type: "nested", //  NOTE:  We store in an Elastic Keyword field so the relationship is searchable.
      properties: {
        functionName: { type: "keyword" },
        params: { type: "nested", dynamic: "strict" }  // TODO:  Need more work here!
      }
    };
  }
}


//
// HSDTStatement Serializer
//

const HSDTStatementSchema  = new DTObject({
  required: true,
  extensible: true,  //  TODO:  Remove this and extend from the SDT schema.
  properties: {
    type: new DTOption({
      required: true,
      options: ["statement"]
    })
  }
});


//  Parse JSON to a HStatementType
export const HSDTStatementParser = async (sdt: HSDTStatement, metadata?: any): Promise<HDTStatement> => {

  //  Validate
  const validatedSDT = await HSDTStatementSchema.validate(sdt);

  const serializedTypeCopy: any = { ...validatedSDT };
  delete serializedTypeCopy["type"];
  const hTypeStatementOptions: HDTStatementOptions = { ...serializedTypeCopy };

  //  Create the HTypeStatement
  const hTypeStatement = new HDTStatement(hTypeStatementOptions, metadata);
  return hTypeStatement;
};
