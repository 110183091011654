import * as yup from 'yup';
import { DT, DTOptions } from '../type-core';

export interface DTBooleanOptions extends DTOptions {}

export class DTBoolean<MetadataType = any> extends DT<boolean, MetadataType> {

  public name = 'Boolean';

  constructor(public options: DTBooleanOptions = {}, protected metadata?: MetadataType) {
    super(options);
  }

  public async validate(input: boolean): Promise<boolean | undefined> {

    //  Super Validation
    input = await super.validate(input);

    //  Handle Undefined
    if (input === undefined) { return undefined; }

    //  Validate Input
    const valid = await yup.bool().isValid(input);
    if (!valid) { this.failValidation(input); }
    return input;
  }

  public async getElasticSchema() {
    return {
        type: 'boolean'
    };
  }
}

