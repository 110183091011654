import { SDT, AllSDT, SDTObject, SDTSerializedSchemaProperties, DTObject, SDTSchemaProperties, DTOption, DTKeyword } from "davel";
import { HSDTColor } from "./color";
import { NounId } from "./identifiers";

//  TODO:  Is it strange these type defs are separated from their parsers?
//  TODO:  These was moved from the Habor core... Should we figure out a more manageable way to synchronize Habor and the SDK.  Sub-modules?

export interface HSDTUserFunction extends SDT {
  type: "user-function";
}

export interface TMSerializedNoun extends SDT {
  type: "noun";
  nounId: string;
}

export interface HSDTStatement extends SDT {
  type: "statement";
}

export interface HSDTDerived extends SDT {
  type: "derived";
  path: string[];
}

export interface HSDTRelationship extends SDT {
  type: "relationship";
  allowMany?: boolean;
  nounIds: NounId[];
}

export interface HSDTDependency extends SDT {
  type: "dependency";
  nounId: string;
}

export interface HSDTType extends SDT {
  type: "custom-type";
  typeId: string;
}

//  HSDT Type Schemas
export const HSDTTypeSerializedSchema: SDTObject = {
  type: "object",
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: "option", options: ["custom-type"] },
    typeId: { type: "keyword", required: true }
  }
};

export const HSDTTypeSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['custom-type'] }),
    typeId: new DTKeyword({ required: true }),
  }
});

//  HSDT Dependency Schemas

export const HSDTDependencySerializedSchema: SDTObject = {
  type: "object",
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: "option", options: ["dependency"] },
    nounId: { type: "keyword", required: true }
  }
};

export const HSDTDependencySchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['dependency'] }),
    nounId: new DTKeyword({ required: true }),
  }
});

export type AllTMSDTs = AllSDT | HSDTUserFunction | TMSerializedNoun | HSDTStatement | HSDTRelationship | HSDTDependency | HSDTColor | HSDTType | HSDTDerived;
