import { DTKeyword, DTDate } from "davel";

export const tmObjectSchemaProperties = {
  id: new DTKeyword({ required: true }),
  created: new DTDate({ required: true }),
  updated: new DTDate({ required: true })
};

export const serializedTMObjectSchemaProperties = {
  id: { type: "keyword", required: true },
  created: { type: "date", required: true },
  updated: { type: "date", required: true }
};

export const TMObjectElasticProperties = {
  id: { type: "keyword" },
  created: { type: "date" },
  updated: { type: "date" }
};

export interface TMObject {
  updated?: string;
  created: string;
  id: string;
}