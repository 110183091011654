import { EventEmitter } from 'events';
import { CorePluginClass } from 'halia';
import * as React from 'react';
import { Text } from 'react-native';
import { HaliaComponentPlugin } from "halia-native";
import { MenuLayout } from "./menu-layout";
//  TODO:  EVENTUALLY consider using HALIA to build these kidns of thigns... Mixins are cool and all, BUT ... they like... don't hav ethe same ability to use the CUSTOM interface hmm.... BUT in Halia HOW can we support inter/intra plugin inheritnace?


//  SHOULD be able to build a componnt with this systemm....  It's JUST a SET with SHIT int it... encodings hm!!  We SHOLD b eable to add and shit hm!

// export interface ComponentPlugin extends NamedObject, Identifiable {

// }


// export class PrepositionalComponentPlugin extends CorePluginClass {

//   static details = {
//     id: "prepositional",
//     name: "Prepositional",
//     description: "Supports Prepositional Injection",
//     dependencies: [HaliaComponentPlugin.details.id]
//   }

//   public above: any[] = [];
//   public below: any[] = [];
//   public main: any = undefined;

//   public addAbove = (comp: any) => {
//     this.above.push(comp);
//   }

//   public addBelow = (comp: any) => {
//     this.below.push(comp);
//   }

//   public registerMain = (comp: any) => {
//     this.main = comp;
//   }

//   public install = (program, { haliaComponentPlugin }: { haliaComponentPlugin: HaliaComponentPlugin }) => {

//     haliaComponentPlugin.registerHOC()
//     return this;
//   }
// }


//  DUDE!  TOTALLLYY build these with a HALIA STACK HM!!  I LOVE that idea I thingk???sjdf j!



export interface MenuItem {
  name: string;
  component: any;
  icon: { name: string, type: string };
  isInitial?: boolean;
  headerRight?: (props: any) => React.ReactNode;
}

export interface MenuLayoutPluginOptions {
  showHeader: boolean;
}

//  NOTE:  WOULD be SO nice if this CLASS could just be added to the state tree ugh

export class MenuLayoutPluginBase extends CorePluginClass {

  public options: MenuLayoutPluginOptions;
  constructor({ showHeader = false }: MenuLayoutPluginOptions) {

    super();
    this.options = { showHeader };

  }

  //  TODO-NEXT:  Use an HOC Register or something to get these showing up in the component.  BUT ... we shold support MULTIPLE contexts.. NOT ust one! UGH!  To re-use a plugin hmm.. like an isntanc hmm...

  public above: any[] = [];
  public below: any[] = [];
  // public main: any = undefined;

  public addAbove = (comp: any) => {
    this.above.push(comp);
    this.menuItemEmitter.emit("new-above", this.above);
  }

  public addBelow = (comp: any) => {
    this.below.push(comp);
    this.menuItemEmitter.emit("new-below", this.below);
  }

  // public registerMain = (comp: any) => {
  //   this.main = comp;
  // }

  //  TODO:  Don't couple this with the plugin!  We MAY have MULTIPLE builder contexts, and we want to add this with a coupler?? HM!  NOT as a plugin for the original but for like ... installed builder plugin?? Hm sdfjsfj
  public static optionsComponent = () => {
    return (
      <Text>OPTIONS!</Text>
    );
  }

  public id = new Date().getTime();

  public menuItems: MenuItem[] = [];

  public menuItemEmitter = new EventEmitter();

  public registerMenuItem = (name: string, item: MenuItem) => {
    this.menuItems.push(item);
    // alert("REGISTERING: " + JSON.stringify(this.menuItems) + " - DATE - " + this.id);
    this.menuItemEmitter.emit("new-item", this.menuItems);
  }

}

// export class MenuLayoutSplitterPlugin extends MenuLayoutPluginBase {

//   public static details = {
//     id: "menuLayout",
//     name: "Menu Layout",
//     description: 'Menu Layout',
//     dependencies: [HaliaComponentPlugin.details.id],
//   }

//   //  TODO:  Window support should be injected into the parent, and THIS into the Window!  OR.. just make it like another nested nevigator thing hmm... EITHER way.. they shuldn't be COUPLED.. hmmm...but MAYBE we have coupling plugins to help with it hmm... interesting... MAYBE we can DYNAMICALLY suggest layouts and shit based on the interfaces we have defined.. like Component / Comonents hmm... BUT also require certain info ABOUT those things.. like CATEGORY and shti hm!!  LOVE that!   h mmm
//   protected windowItems: any[] = [];
//   public registerWindow = (windowChild) => { this.windowItems.push(windowChild) }

//   //  TOOD:  STILL DON'T like that the name -> thing biding happens here!  I feel like my confidence is shot ughhh suckss.. I want to thin kin generalization and abstractions and shit but ughh..
//   public install = (program, { haliaComponentPlugin }: { haliaComponentPlugin: HaliaComponentPlugin }) => {
//     haliaComponentPlugin.registerHOC(() => (
//       <Splitter>
//         <Window><MenuLayout layoutPlugin={this} /></Window>
//         {this.windowItems.map(child => <Window>{child}</Window>)}
//       </Splitter>
//     ));
//     return this;
//   }
// }

export class MenuLayoutPlugin extends MenuLayoutPluginBase {

  public static details = {
    id: "menuLayout",
    name: "Menu Layout",
    description: 'Menu Layout',
    dependencies: [HaliaComponentPlugin.details.id],
  }

  public MenuLayout = () => <MenuLayout layoutPlugin={this} />
  //  TOOD:  STILL DON'T like that the name -> thing biding happens here!  I feel like my confidence is shot ughhh suckss.. I want to thin kin generalization and abstractions and shit but ughh..
  public install = (program, { haliaComponentPlugin }: { haliaComponentPlugin: HaliaComponentPlugin }) => {
    // alert("Installing");
    haliaComponentPlugin.registerHOC(this.MenuLayout);
    return this;
  }
}


// Halia Class?? HM!  THEN we can use THIS to build the plugins? Hmm... LIKE... think of it like a template .. then. when we want to USE it .. we BAKE it hmm...

//  TODO:  REALLY want to support this.. then be able to MAKE a plugin using the template and BAKE it by usin an API to produce a CLASS HM!  I THINK that makes some sense.. THEN it's BASICALLY a compiler BUT at runtime hm!
// export interface HaliaClassTemplate extends NamedObject, Identifiable {

// }

// export interface ClassMethod extends NamedObject, Identifiable {
//   // visibility: 'public' | 'private';
//   //  TODO:  Support TYPES and stuff here!
//   method: (props: any) => any;
// }

//  TODO:  BUILD this ClassBuilder with a fucking plugin system so we can add thigns like public private etc? asdjlf
//  CONSIDER:  Do we REALLY want to actually ADD the methods here, OR should we use an INHERTIANCE system? Hmlsdjf
// export class ClassBuilder extends CorePluginClass {
//   public baseClass
//   public getClass = () => {}
//   public methods: ClassMethod[] = [];
//   public setBaseClass = 
//   public addMethod = (method: ClassMethod) => {
//     //  TODO:  How to handle inheritnace?
//     this.methods.push(method);
//   }
// }

// export 