import { SDTObject } from 'davel';
import { Noun } from '../models';
import { InstanceSearchParams, NounSearchParams } from '../sdks/habor-api';
import { HDTRelationshipValue } from '../types';
import { valueAtPath } from '../utils';
import { EntityType } from './identifiers';
import { AllTMSDTs } from './types';

//  MAYBE we have two different renderers, Noun and Instance?  We can attach both to a Noun, and that determines how the instances are rendered.  OR, we can attach to the instance to override?  We should also process the inheritance chain.  FOR NOW, let's assume that the "Noun" renderer is separate and static... We can CONSIDER making a new class called "Entity" which MAY let us use this pattern to select how that renderer is displayed.

//  CONSIDER:  ADDITIONAL built-in ViewTypes.
//  CONSIDER:  Custom Views!  The user can create NEW ViewTypes.
//  NOTE:  Classically, a "View" is a view of the DATA, not the UI Representation.
//  NOTE:  Each of these "Views" may have settings that DIFFER, but the input interface looks the same for a type?

//  QUESTION:  Whose responsibility is it to do Filtering / Searching?  Is it the list view or the app?  I would suggest the view FOR NOW.  It's POSSIBLE that within the View, we have an EMBEDDED view which is ALSO configurable under a DIFFERENT interface which INCLUDES that stuff!  REALLY we're defining the ComponentInterface and letting the user upload SEVERAL Components which MEET that interface.  Then, we let them select the interface for a set of prop conditions??
//             So, the first thing we do is let the user register a new ComponentType.  Then, we can upload components for that type.  THEN, when we want the user to select one, we use a "ComponentRouter" or "ComponentSelection" which lets the user select which component to use of the registered components.  That selection will be stored with the given input map!  SO, the SELECTOR has an ID which is the hash of the relevant props?  Hmmm...


//  BRAINSTORM:  We use "ComponentRouters" and we register, JUST like CSS, RouteSetting objects.  We already have some of that done.  Then, the router selects a component based on the settings and that component has associated settings?
//               The first case is the Workspace editor.  How is that going to work?  Because it's currently considered a TrackMine primitive, let's hard-code it for now.  Even though it CAN be hard-coded, we can still use the selection mechanism once it's ready!  Even if we just hard-code the values.


/**
 * A Component is said to be an instance of a ComponentClass if it accepts the props specified on the class.
 * NOTE:  FOR NOW, overrides are not permitted.  This is because the UI will be expecting those props.
 */
export interface HaborComponentClass {
  name: string;
  description?: string;
  props?: SDTObject;
  settings?: SDTObject;
}


//  TODO:  Apply RULES to make sure that the component attached as a Class REALLY obets
export const HaborComponentClassNoun: Noun = {
  id: "habor-component-class",
  name: "Habor Component Class",
  description: "Class definition for a Habor Component.",
  properties: {
    name: {
      name: "name",
      description: "The name of the Habor Component",
      type: { type: "keyword", required: true }
    },
    description: {
      name: "description",
      description: "Optional description of this Habor Component Class.",
      type: { type: "text", required: false }
    },
    props: {
      name: "props",
      description: "Props Schema Definition",
      type: { type: "sdt", itemType: "object", required: false }
    },
    settings: {
      name: "settings",
      description: "Settings Schema Definition",
      type: { type: "sdt", itemType: "object", required: false }
    }
  }
};

//  NOW, we need to store the corresponding components for the class.  We can do this when we define the component!


//  TODO:  Do an inventory / audit of HaborValue usage... Make sure it makes sense... I'm hesitant, because it seems like we're passing it with EXPLICIT values each time... Instead, we could pass with contextual info, enough to derive a reasonable type when needed?

/**
 * State Variables have Runtime Types
 */
export interface HaborValue {
  type: AllTMSDTs;
  value: any;
}

export interface ChainLink {
  name: string;
  params: any;
}

/**
 * A reference to something that will eventually produce a value.
 */
export interface HaborDeferredValueBase {
  type: string;
}

export interface HaborPrimitive extends HaborDeferredValueBase {
  type: "primitive";
  value: HaborValue;
}

/**
 * References a Scope Variable
 */
export interface HaborSymbol extends HaborDeferredValueBase {
  type: "symbol";
  scopePath: string[];
}

export interface HaborChain extends HaborDeferredValueBase {
  type: "chain";
  links: ChainLink[];
}

/**
 * NOTE:  This is a SPECIAL value that only applies in the context of a chain.
 * TODO:  Remove this!  I REALLY don't like specialized things like this... we need to use a more general pattern... Maybe consider supporting callbacks?
 * TODO:  IF we keep this, then it should be scoped to ONLY be supported when used in the context of a HaborChainLink input?
 * TODO-IMPORTANT:  Should be able to sub-select from link input!
 */
export interface HaborLinkInput extends HaborDeferredValueBase {
  type: "link-input";
  inputPath?: string[];
}

export type HaborDeferredValue = HaborPrimitive | HaborSymbol | HaborChain | HaborLinkInput;


export interface TypeTree {
  [name: string]: AllTMSDTs | TypeTree;
}
/**
 * Generic Functional Variable Stack
 * NOTE:  Types and Values are stored separately to avoid re-writing deep primitive structures to include typing info at each level of the tree.
 */
export interface HaborScope {

  value: { [name: string]: any };
  types: TypeTree;
}

/**
 * Later elements in a ScopeStack override earlier elements.
 */
export type ScopeStack = HaborScope[];

//  A hierarchy with realized prop values.  Each HaborElement maps to a ReactNative element.
export interface HaborElement {
  name: string;  //  Name of the primitive UI Component to render.
  children: HaborElement[];
  props: {
    [name: string]: any;
  };  //  Props to pass to the primitive UI Component
}

/**
 * Link from JS to HaborComponent Tree.  All props are Primitives in the first layer.
 */
export interface HaborElementBridgeDeclaration {
  name: string;
  children: HaborElementDeclaration[];
  props: {
    [name: string]: any;
  };
}


//  Element declaration to be converted to a HaborElement by binding the Scope variables to values.
//  NOTE:  This corresponds to a JSX Declaration in React or nested 'createElement' calls.
export interface HaborElementDeclaration {
  name: string;  //  Primitive or HaborComponent Name
  children: HaborElementDeclaration[];
  props: {
    [name: string]: HaborDeferredValue;
  };
}

export enum QueryType {
  Noun = "noun",
  Instance = "instance"
}
export interface HaborNounQuery extends NounSearchParams {
  type: "noun";
}
export interface HaborInstanceQuery extends InstanceSearchParams {
  type: "instance";
}

export type HaborQuery = HaborNounQuery | HaborInstanceQuery;


export interface ChainLinkDefinition {
  name: string;
  description?: string;
  paramsType: AllTMSDTs;
  resolver: ChainLinkResolver;
}

/**
 * Maps input values to output values with a provided map.  How to deal with nested?  How to deal with recursive / array structures?  FOR NOW, just map out the object, and select the portion of the input you'd like to use.  THEN, add additional filters?  One we have an array selected, we can use an Array "Map" filter to transform the array with several other object maps??  WHY NOT just use functions?  Functions involve storing additional, ephemeral state in the model which I'm trying to avoid.
 */
export interface HaborReselection {
  outputPath: string[];
  value: HaborDeferredValue;
}

//
//  Deferred Value Resolvers
//


export const resolvePrimitive = (primitive: HaborPrimitive, scope: HaborScope): HaborValue => {
  const { value } = primitive;
  return value;
};

export const resolveSymbol = (symbol: HaborSymbol, scope: HaborScope): HaborValue => {
  const { scopePath } = symbol;
  const primitiveValue = valueAtPath(scope.value, scopePath);
  const valueType: AllTMSDTs = { type: "any" };  //  TODO-IMPORTANT:  If type is not explicitly defined for this Scope level, then check the parents to derive the type as accurately as possible.
  return { value: primitiveValue, type: valueType };
};

export const resolveChain = (chain: HaborChain, scope: HaborScope, input: any = undefined): HaborValue => {
  const { links } = chain;
  let prevRes: HaborValue = input;
  for (const link of links) {
    prevRes = resolveLink(link, scope, prevRes);
  }
  return prevRes;
};

export const resolveLinkInput = (linkInput: HaborLinkInput, scope: HaborScope, input: HaborValue): HaborValue => {
  const { inputPath = [] } = linkInput;
  const rawValue = valueAtPath(input.value, inputPath);
  return { value: rawValue, type: { type: "any" } };
};

export type DeferredValueResolver = (value: HaborDeferredValue, scope: HaborScope, linkInput?: HaborValue) => HaborValue;
export const deferredValueResolvers: { [type: string]: DeferredValueResolver } = {
  primitive: resolvePrimitive,
  symbol: resolveSymbol,
  chain: resolveChain,
  "link-input": resolveLinkInput
};

export const resolveDeferredValue = (value: HaborDeferredValue, scope: HaborScope, linkInput?: HaborValue): HaborValue => {
  const { type } = value;
  const resolver = deferredValueResolvers[type];
  if (resolver == undefined) { throw new Error(`No DeferredValueResolver found for type: '${ type }'`); }
  return resolver(value, scope, linkInput);
};


//
//  Reselection ChainLink
//

export interface ReselectionParams {
  reselections: HaborReselection[];
}

export const resolveReselection = ({ reselections }: ReselectionParams, scope: HaborScope, input?: HaborValue) => {

  const newObj = {};
  for (const reselection of reselections) {
    const { outputPath, value } = reselection;
    const resolvedValue = resolveDeferredValue(value, scope, input);
    setValueAtPath(newObj, outputPath, resolvedValue.value);
  }
  return {
    value: newObj,
    type: { type: "object" as "object", extensible: true }  //  TODO-IMPORTANT:  Combine the elements to create a more specific type.
  };
};

export const ReselectChainLinkDefinition: ChainLinkDefinition  = {
  name: "reselect",
  description: "Reselects from an input type to an output type.",
  paramsType: { type: "any", required: true },
  resolver: resolveReselection
};

export interface ReselectChainLink extends ChainLink {
  name: "reselect";
  params: ReselectionParams;
}


//
//  Map ChainLink
//

export interface MapParams {
  //  NOTE:  "list" should resolve to an Array.
  list: HaborDeferredValue;  //  TODO:  We should probably have a DT for the type of the variable to make sure it matches!
  chain: HaborChain;
}

export const resolveMap = ({ list, chain }: MapParams, scope: HaborScope, input?: HaborValue) => {

  const resolvedList = resolveDeferredValue(list, scope);
  const resolvedListValue: any[] = resolvedList.value;
  const newList = resolvedListValue.map(item => {
    const itemHaborValue: HaborValue = { value: item, type: { type: "any" } };
    const newValue = resolveDeferredValue(chain, scope, itemHaborValue);
    return newValue.value;
  });
  return { value: newList, type: { type: "any" as "any" } };
};

export const MapChainLinkDefinition: ChainLinkDefinition  = {
  name: "map",
  description: "Runs a Chain for each member of an Array.",
  paramsType: { type: "any", required: true },
  resolver: resolveMap
};

export interface MapFilterLink extends ChainLink {
  name: "map";
  params: MapParams;
}


//
//  ChainLink Registration
//

export type ChainLinkResolver = (params: any, scope: HaborScope, input: HaborValue) => HaborValue;
export const chainLinkRegister: { [type: string]: ChainLinkDefinition } = {
  reselect: ReselectChainLinkDefinition,
  map: MapChainLinkDefinition
};


//
//  ChainLink Resolver
//

export const resolveLink = (link: ChainLink, scope: HaborScope, input: HaborValue): HaborValue => {
  const { name, params } = link;
  const linkDefinition = chainLinkRegister[name];
  if (linkDefinition == undefined) { throw new Error(`No ChainLinkDefinition found for type: '${ name }'`); }
  const linkResolver = linkDefinition.resolver;
  const value = linkResolver(params, scope, input);
  return value;
};

//
//  Utils
//

export const setValueAtPath = (obj: any, path: string[], value: any) => {
  let nestedObj = obj;
  for (let i = 0; i < path.length; i++) {
    const pathPart = path[i];
    if (i == path.length - 1) {
      nestedObj[pathPart] = value;
    } else {
      nestedObj[pathPart] = nestedObj[pathPart] || {};
      nestedObj = nestedObj[pathPart];
    }
  }
};


//
//  HaborComponents
//

//  Binding of Props, Query Result, and a HaborElementDeclaration.
export interface HaborComponent {
  name: string;
  propsSchema?: SDTObject;  //  Input Params.
  query?: HaborQuery;  //  Sources to generate the State.
  element: HaborElementDeclaration;  //  Elements are the return value of a Habor Component used to realize the UI.
  classes?: string[];  //  TODO:  FOR NOW, we're storing the NAMES of the classes.  In the future, we may want to store the ID.  OR, just use "Nouns" as Classes and avoid this issue altogether (because classes have names).
  settingsSchema?: SDTObject;
}

export interface SerializedHaborComponent {
  name: string;
  propsSchema?: SDTObject;
  query?: string;
  element: string;  //  Serialized Element
  classes?: string[];
  settingsSchema?: SDTObject;
}

//  TODO:  Instead of serializing these elements, can we update the type so they are stored as objects??
export const deserializeHaborComponent = (serHaborComponent: SerializedHaborComponent) => {
  const haborComponent: HaborComponent = {
    name: serHaborComponent.name,
    propsSchema: serHaborComponent.propsSchema,
    ...( serHaborComponent.query ? { query: JSON.parse(serHaborComponent.query) } : {} ),
    element: JSON.parse(serHaborComponent.element),
    classes: serHaborComponent.classes,
    settingsSchema: serHaborComponent.settingsSchema
  };
  return haborComponent;
};

export const serializeHaborComponent = (haborComponent: HaborComponent) => {
  const serializedHaborComponent: SerializedHaborComponent = {
    name: haborComponent.name,
    propsSchema: haborComponent.propsSchema,
    ...( haborComponent.query ? { query: JSON.stringify(haborComponent.query) } : {} ),
    element: JSON.stringify(haborComponent.element),
    classes: haborComponent.classes,
    settingsSchema: haborComponent.settingsSchema
  };
  return serializedHaborComponent;
};

export const SerializedHaborComponentSchema: SDTObject = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    name: { type: "keyword", required: true },
    propsSchema: { type: "sdt", itemType: "object", required: false },
    query: { type: "text", required: false },
    element: { type: "text", required: true },
    classes: { type: "array", itemType: "keyword" },
    settingsSchema: { type: "sdt", itemType: "object", required: false },
  }
};

export const SerializedHaborComponentNoun: Noun = {
  id: "serialized-habor-component",
  name: "Serialized Habor UI Component",
  description: "A Serialized Habor UI Component.",
  properties: {
    name: {
      name: "name",
      description: "The name of the Habor Component",
      type: { type: "keyword", required: true }
    },
    propsSchema: {
      name: "propsSchema",
      description: "Schema for the Habor Component Props.",
      type: { type: "sdt", itemType: "object", required: false },
    },
    query: {
      name: "query",
      description: "The Habor Query to Perform once Mounted", //  CONSIDER:  Removing this and supporting a more general functional thing... I like the idea of users making some sort of functions with a visual editor?
      type: { type: "text", required: false }
    },
    element: {
      name: "element",
      description: "Habor UI Element Tree",
      type: { type: "text", required: true }
    },
    classes: {
      name: "classes",
      description: "Habor Component Class List",
      type: { type: "array", itemType: { type: "keyword" } }
    },
    settingsSchema: {
      name: "settingsSchema",
      description: "Schema for the Habor Component Settings.",
      type: { type: "sdt", itemType: "object", required: false }
    }
  }
};

export interface HaborComponentConfig {
  onPress: (componentId: string) => void;
  editMode: boolean;
}


export const mockComponent: HaborComponent = {
  name: "mockComponent",
  propsSchema: { type: "object", extensible: false, properties: { propTest: { type: "keyword", required: true } } },
  query: { type: "noun" },
  element: {
    name: "TestView",
    children: [{
      name: "TestSubView",
      children: [],
      props: {
        propTest: { type: "symbol", scopePath: ["props", "propTest"] }
      }
    }],
    props: {
      nouns: { type: "symbol", scopePath: ["state", "queryResult"] },
      propTest: { type: "symbol", scopePath: ["props", "propTest"] }
    }
  }
};

