import * as yup from 'yup';
import { SDT, SDTSchemaProperties, SDTSerializedSchemaProperties } from '../parser-core';
import { DTBinary, DTBinaryOptions, DTObject, DTOption } from '../types';
import { SDTObject } from './object';
import { SDTYup } from '../util';

//  TODO-IMPORTANT:  The distinction between binary and text exists at an abstraction level that Davel should not be aware of.  In fact, we should be removing "elasticSchema" concepts entirely and map the DTs to Elastic Schemas outside of Davel... Maybe they don't even map 1-1 or maybe there are several layers of types before it hits the DB.  So, we do need to get that info at some level, possibly from the user, or learned, but it shouldn't be encoded here.
export interface SDTBinary extends SDT {
  type: 'binary';
}

const SDTBinaryYup = SDTYup.shape({
  type: yup.string().required().matches(/binary/),
});

export async function SDTBinaryParser<MetadataType = any> (serialized: SDTBinary, metadata?: MetadataType) {

  //  Validate
  const valid = SDTBinaryYup.validateSync(serialized);
  if (!valid) { throw new Error(`Binary schema validation failed.`); }

  const serializedCopy: any = { ...serialized };
  delete serializedCopy['type'];
  const stringOptions: DTBinaryOptions = { ...serializedCopy };

  //  Create the DTBinary
  const dtBinary = new DTBinary(stringOptions, metadata);
  return dtBinary;
}

//  Schema to Validate an Binary SDT
export const SDTBinarySerializedSchema: SDTObject = {
  type: 'object',
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: 'option', options: ['binary'] }
  }
};

export const SDTBinarySchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['binary'] })
  }
});
