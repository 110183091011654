/**
 * Copyright (C) William R. Sullivan - All Rights Reserved
 * Written by William R. Sullivan <wrsulliv@umich.edu>, January 2019 - April 2019
 */

//  TODO-CRITICAL:  THIS FILE IS MODIFIED FROM HABOR.
//                  This makes it possible to validate a noun in the UI, BUT should it use the API?  That would cause a freeze... unless we have a standard API that uses HTTP when in the UI and runs locally in Habor!  I like this idea!  Either way, the things passed in the metadata will be a Habor SDK with the same interface!

//  IDEA:  Instead of duplicating, we can make a Type Target, which is like a thing / context in which a Type is processed.  This way it MAY have different logic in the backend vs. front, BUT I'd like to use STANDARD funcions and stuff to make this work.. rregularr rregistration patter?? HM!  Which is JUST like a way to build an abstrractin?  New symbol / interpreters.  This is a register symbol and interrpreterrs which will execute with proper params!

import { deserializeSDT, DT, DTOptions, SDTAny, SDTObject, SDTText, DTAnyValue } from "davel";
import { HSDTDerived } from "../models";

export type HDTDerivedValue = any;  //  NOTE:  We don't know the value until we check the type.  BUT, it's going to be stored as "any" for now.

//  NOTE:  We will convert to / from text.
export const HDTDerivedValueSerializedSchema: SDTAny = { type: "any" };

//  TODO:  Consider the more general "Object Selection" pattern, where we have a Whitelist and a Blacklist instead of JUST a simple list of Nouns.  Then we CAN work toward more complex selection queries.
//  TODO-FUTURE:  Support undefined 'nounIds' to support ANY Noun... Currently this comes with at least 2 technical challenges.  One, we create a "Remote Property Declaration" on nouns in this list, so we need to know what they are... It seems dangerous to apply the declaration to EVERY Noun... Two, in the UI we need to SEARCH for instances to make our selection, and unbounded (by Noun) search is NOT currently supported.
export interface HDTDerivedOptions extends DTOptions {
}

export class HDTDerived extends DT<HDTDerivedValue, any> {

  public name = "Derived";

  constructor(public options: HDTDerivedOptions, metadata?: any) {
    super(options);
  }

  public async validate(value: HDTDerivedValue): Promise<HDTDerivedValue | undefined> {

    //  Super Validation
    value = await super.validate(value);

    //  Handle Undefined
    if (value === undefined) { return undefined; }

    //  Parse the Schdema
    const dt = await deserializeSDT(HDTDerivedValueSerializedSchema);

    //  Validate Input
    value = await dt.validate(value);

    //  TODO-CRITICAL:  Verify that the value is a proper instance of the derived type.

    //  Validate Params
    return value;
  }

  public async getElasticSchema() {
    const dt = await deserializeSDT(HDTDerivedValueSerializedSchema);
    const esSchema = await dt.getElasticSchema(false);
    return esSchema;
  }
}

export const HSDTDerivedSchema: SDTObject = {
  type: "object",
  required: true,
  extensible: false,
  properties: {
    type: {
      type: "option",
      options: ["derived"],
      required: true,
    },
    path: {
      type: "array",
      itemType: { type: "keyword" },  //  TODO:  Validate the path is valid?  MAYBE track path dependencies??
      required: true
    }
  }
};

export const HSDTDerivedParser = async (sdt: HSDTDerived, metadata?: any): Promise<HDTDerived> => {

  //  Deserialize Type
  const dt = await deserializeSDT(HSDTDerivedSchema);

  //  Validate
  const validatedSDT = await dt.validate(sdt);

  const serializedTypeCopy: any = { ...validatedSDT };
  delete serializedTypeCopy["type"];
  const hTypeRelationshipOptions: HDTDerivedOptions = { ...serializedTypeCopy };

  //  Create the HTypeRelationship
  const hTypeRelationship = new HDTDerived(hTypeRelationshipOptions, metadata);
  return hTypeRelationship;
};
