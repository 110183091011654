import { TypeSpace, selectTypeSpace } from 'davel';

export * from 'davel';  //  TODO:  Remove this!
export * from './sdks';
export * from './models';
export * from './types';
export * from './utils';

export const initDavel = (typespace: TypeSpace) => {
  selectTypeSpace(typespace);
};