import * as yup from 'yup';
import { SDT, SDTSchemaProperties, SDTSerializedSchemaProperties } from '../parser-core';
import { DTArray, DTKeyword, DTObject, DTOption, DTOptionOptions } from '../types';
import { SDTObject } from './object';
import { SDTYup } from '../util';

export interface SDTOption extends SDT {
  type: 'option';
  options: string[];
}

const SDTOptionYup = SDTYup.shape({
  type: yup.string().required().matches(/option/), //  TODO: Does this need to be changed to valid?
  options: yup.array().of(yup.string()).required()
});

export async function SDTOptionParser<MetadataType = any> (serialized: SDTOption, metadata?: MetadataType) {

  //  Validate
  const valid = SDTOptionYup.validateSync(serialized);
  if (!valid) { throw new Error(`Option schema validation failed.`); }

  const serializedCopy: any = { ...serialized };
  delete serializedCopy['type'];
  const optionOptions: DTOptionOptions = { ...serializedCopy };

  //  Create the DTOption
  const dtOption = new DTOption(optionOptions, metadata);
  return dtOption;
}

//  Schema to Validate an Option SDT
export const SDTOptionSerializedSchema: SDTObject = {
  type: 'object',
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    //  TODO:  Consider a type of "constant" instead of "options" mm!
    type: { type: 'option', options: ['option'] },
    options: { type: 'array', required: true, itemType: { type: 'keyword' } },
  }
};

export const SDTOptionSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['object'] }),
    options: new DTArray({ required: true, itemType: new DTKeyword({}) }),
  }
});
