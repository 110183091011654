import { namedObject, NamedObject, namedObjectSerializedSchema } from "./named-object";
import { Noun } from "./noun";

export interface Workspace extends NamedObject {
  // defaultPage: HDTRelationshipValue;
}

//  NOTE:  I think a Plugin can have a lots of different elements, but a SYSTEM, when tapped in the UI will take us to the main screen where we can interact with that system... Maybe a set of Objects and Actions?  MAYBE, to start we can do this within a Plugin and then move to a System?  Maybe a plugin exposes a Default UI?  I'm not sure... FOR NOW, let's just get the system compiling and come back to this!
//  TODO-IMPORTANT:  Make sure the "contents" field is NOT stored in the Primitive model.  Otherwise, it duplicates the IDs.  We want it to be stripped by our outer layer Plugin with Hooks / Middleware.  CONSIDER removing it from the Primitive interface... But it MAY be ok if it's stripped by the Plugin!
//  TODO-IMPORTANT:  Eventually scope the "contents" to only allow "Plugins".  I didn't do it now because I don't want to REQUIRE the user to use the Primitive API to make the relationship.

//  NOTE:  "Workspace" is a "TrackMine" / "Hessia" Primitive.  That means the front-end has support for this object by default.  This is why style is hard-coded.
//         FOR NOW, we're going to hard-code this in the UI.  In the NEAR future, as we build up tools and functionality, we can start using less and less hard-coded logic / UI.

//  CONSIDER:  In the future, consider allowing a user to define custom app elements (like pages) on the Workspace.

//  NOTE:  The "Class" system SUPPORTS fields with explicit relationships.  However, we MIGHT want to consider typing these internally to the host + property name?  Hmmm... these are a CERTAIN type of relationship known to the CLASS system which represent a PROPERTY.

//  SO... with all these systems defining their OWN meaning, how do we ensure we're not RE-CREATING things in a derivative way?  That's an interesting question, BUT I think it's OUTSIDE THE SCOPE of the immediate Hessia implementation!!!  IT MAY be important to consider this in the future though!

export const workspaceNoun: Noun = {
  id: "workspace",
  name: "Workspace",
  description: "A Workspace",
  inherits: [namedObject.id],
  properties: {
    // plugins: {
    //   name: "plugins",
    //   description: "Enabled Plugins",
    //   type: { type: "relationship", nounIds: [{ nounId: pluginNoun.id, type: EntityType.Noun }], allowMany: true }
    // },
    // //  TODO:  Remove this... not going to have this as a default Workspace thing.
    // defaultPage: {
    //   name: "defaultPage",
    //   type: { type: "relationship", nounIds: [{ nounId: PageNoun.id, type: EntityType.Noun }], allowMany: false }
    // }
  }
};

export const workspaceSerializedSchema = {
  ...namedObjectSerializedSchema,
  // plugins: { type: "relationship", nounIds: [{ nounId: pluginNoun.id, type: EntityType.Noun }], allowMany: true },
  // defaultPage: { type: "relationship", nounIds: [{ nounId: PageNoun.id, type: EntityType.Noun }], allowMany: false }
};
