import { NamedObject, namedObject } from "./named-object";
import { Noun } from "./noun";
import { Relationship, RelationshipNoun } from "./relationship";
import { InstanceID, ObjectID } from "./identifiers";

export interface Plugin extends NamedObject {
  dependencies?: string[];
}

//  TODO-CRITICAL:  REMOVE THIS in favor of "Plugin"!!!

//  OK, we're officially at an impass... I want users to be able to add lots of elements to a Plugin, BUT that includes BOTH Nouns and Instances, and maybe other objects... with a standard relationship like this, I can only add Instances... to a property...   SO, I'd like to support SOMETHING else... Maybe make "Relationship" support more than JUST Instances?  I can do this by allowing it to support ANYTHING, BUT having a special case for Nouns?  In this case, it's almost more of a second system... Ah!  This is the "Attachment" system, which IS differnet I believe.. it's about attaching OBJECTS to one another, without regard to a field.  Even so, what if I wanted to attach an Object like a Noun to a FIELD?  Maybe I can make different types for all of that?
//  Hmmm... maybe I can make "Noun" a "Noun", that way, we have a "Noun Instance" for each Noun?  Then I can just select from Nouns like their an Instance?
//  IDEA:  Maybe we have "Systems" own different code elements, like "Relationships"... Then the Outgoing Remote Property Values will AT LEAST have a System context... and MAYBE show something in the UI regarding the REASON for the connection?  I don't like how we aren't differentiating between outgoing relationships.  For example, if there are 2 outgoing relationships from A -> B, with 2 different properties, then they are clustered as Outgoing on A WITHOUT context to the field on B... MAYBE we SHOULD have a TWO-WAY field requirement?  This way, for example, we can have a Task with several Statuses and a Status with SEVERAL types of Task?
//  FOR NOW, what if we remove the "Remote Property Definitions" and JUST support "Remote Property Values".  Hmmm... actually, we COULD just remove this from the "Container" and just let EVERY object act as a container like we were doing!  OK, but we should use the "Relationship" type INSEAD of "Dependency", and I AGREE we SHOULD support 2 way propeties... let's leave "Relationship" as a way to enforce instance connection in the Primitive Layer FOR NOW.  My problem with this, is what field will we put the attachments on?  We COULD just make an "Attachment" type which is interpreted in a special way for this Noun?  Hmmm... Maybe that WILL work... But what if we DO want to scope the container... OK, let's worry about that later.
export const pluginNoun: Noun = {
  id: "plugin",
  name: "plugin",
  description: "A Habor Plugin",
  inherits: [namedObject.id],
  properties: {
    dependencies: {  //  NOTE:  "required" at noun level determines whether or not the KEY is required.  At type level determines whether or not a VALUE is required!!!
      name: "dependencies",
      type: { type: "array", itemType: { type: "keyword" }, required: false }  //  TODO:  Consider a relationship?
    }
  }
};

export interface SystemAssociation extends Relationship {}

export const SystemAssociationNoun: Noun = {
  name: "SystemAssociation",
  id: "system-association",
  description: "Indicates that an object belongs to a System.",
  inherits: [RelationshipNoun.id],
  properties: {}
};

// //  TODO:  Reconsider space extension.  FOR NOW, let's just use ONE space at a time to keep things simple.
// export interface SpaceInheritance extends Relationship {
//   srcId: ObjectID;
//   destId: ObjectID;
// }
// export const SpaceInheritanceNoun: Noun = {
//   name: "SpaceInheritance",
//   id: "space-inheritance",
//   description: "Indicates that a Space extends an existing Space.",
//   inherits: [RelationshipNoun.id],
//   properties: {}
// };

//  TODO:  Reconsider whether or not a Space IS equivalent to a System?  Perhaps we can re-use system instead.
export interface SpaceAssociation extends Relationship {
  destId: InstanceID;
}
export const SpaceAssociationNoun: Noun = {
  name: "SpaceAssociation",
  id: "space-association",
  description: "Indicates that an Object belongs to a Space.",
  inherits: [RelationshipNoun.id],
  properties: {}
};

export interface SystemEnablement extends Relationship {
  srcId: InstanceID;
}
export const SystemEnablementNoun: Noun = {
  name: "SystemEnablement",
  id: "system-enablement",
  description: "Indicates that a System is enabled for a Space.",
  inherits: [RelationshipNoun.id],
  properties: {}
};

export interface SpacePlugins {
  spaceId: ObjectID;
  plugins: ObjectID[];
}

//  NOTE:  FOR NOW, the logic to require ONE Page which HAS been associated with this System is currently HARD-CODED.  Perhaps we can use Habor functions for this in the future?
export interface ConfigurationPage extends Relationship {
  srcId: InstanceID;  //  Page (in this System)
  destId: InstanceID;  //  System
}

export const ConfigurationPageNoun: Noun = {
  name: "ConfigurationPage",
  id: "configuration-page",
  description: "Selects a configuration page for a system.",
  inherits: [RelationshipNoun.id],
  properties: {}
};
