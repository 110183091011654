import * as yup from 'yup';
import { SDT, SDTSchemaProperties, SDTSerializedSchemaProperties } from '../parser-core';
import { DTObject, DTOption } from '../types';
import { DTAny, DTAnyOptions } from '../types/any';
import { SDTObject } from './object';
import { SDTYup } from '../util';

export interface SDTAny extends SDT {
  type: 'any';
}

console.log(SDTYup);
const SDTAnyYup = SDTYup.shape({
  type: yup.string().required().matches(/any/),
});

export async function SDTAnyParser<MetadataType = any> (serialized: SDTAny, metadata?: MetadataType) {

  //  Validate
  const valid = SDTAnyYup.validateSync(serialized);
  if (!valid) { throw new Error(`'Any' Davel Type schema validation failed.`); }  //  TODO:  Standardize error messages.

  const serializedCopy: any = { ...serialized };
  delete serializedCopy['type'];
  const stringOptions: DTAnyOptions = { ...serializedCopy };

  //  Create the DTAny
  const dtAny = new DTAny(stringOptions, metadata);
  return dtAny;
}

//  Schema to Validate an Any SDT
export const SDTAnySerializedSchema: SDTObject = {
  type: 'object',
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: 'option', options: ['any'] }
  }
};

export const SDTAnySchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['any'] })
  }
});