import * as yup from 'yup';
import { SDT, SDTSchemaProperties, SDTSerializedSchemaProperties } from '../parser-core';
import { DTKeyword, DTObject, DTOption, DTSDT, DTSDTOptions } from '../types';
import { SDTObject } from './object';
import { SDTYup } from '../util';


//  An SDTSDT is a schema (SDT) which is used to create a validator (DTSDT) to validate a schema (SDT).
export interface SDTSDT extends SDT {
  type: 'sdt';
  itemType?: string;  //  NOTE:  I would put AnyDTType, but then extensions would fail.  Instead, rely on runtime checking.
}

//  TODO:  Express with DTs
const SDTSDTYup = SDTYup.shape({
  type: yup.string().required().matches(/sdt/),
  itemType: yup.string()
});

export async function SDTSDTParser<MetadataType = any> (serialized: SDTSDT, metadata?: MetadataType) {

  //  Validate
  const valid = SDTSDTYup.validateSync(serialized);
  if (!valid) { throw new Error(`SDT schema validation failed.`); }

    //  Create the Options
    const serializedCopy: any = { ...serialized };
    delete serializedCopy['type'];
    const sdtOptions: DTSDTOptions = { ...serializedCopy };

  //  Create the DTSDT
  const dtSDT: DTSDT = new DTSDT(sdtOptions, metadata);
  return dtSDT;
}

//  Schema to Validate an Type SDT
export const SDTTypeSerializedSchema: SDTObject = {
  type: 'object',
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: 'option', options: ['sdt'] },
    itemType: { type: 'keyword', required: false }
  }
};

export const SDTTypeSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['type'] }),
    itemType: new DTKeyword({ required: false }),
  }
});