import { SDTObject } from "davel";
import { EventEmitter } from "events";
import { InstanceInternal } from "./instance";
import { Noun } from "./noun";
import { APIUser } from "./user";
import { Workspace } from "./workspace";
import { HaborIconSelection } from "./icon";
import { Plugin } from "./plugin";

/**
 * Identifies a position in the live component tree.  Used for the "Settings" system.
 */
export interface HaborComponentContext {
  //  TODO:  Support 'state'?
  //  NOTE:  These should ONLY be user-level props that are critical to the parent component, NOT all the back-channel stuff like token right?
  props?: any;
  name: string;
  parent?: HaborComponentContext;
}

export interface PageSelection {
  pageName?: string;
  pageId?: string;
  props: any;
}

export interface UserCredentialProps {
  user: InstanceInternal<APIUser>;
  token: string;
}

//  TODO!!!:  Just REMOVE this whole thing and use the "App Context" built into React.  MAYBE we'll need something like this in the future, but I think that's FINE for now.
//  TODO:  Consider how we'll handle "Actions" in the UI?  The various systems SHOULD eventually have ways to invoke shit??
export interface FrameworkContext extends UserCredentialProps {
  workspace: InstanceInternal<Workspace>;
  changePage: (pageSelection: PageSelection) => void;
  appEmitter: EventEmitter;
  plugin?: InstanceInternal<Plugin>;
}

export interface FrameworkParamsNew {
  frameworkContext: FrameworkContext;
  componentContext: HaborComponentContext;
  setOutput: (value: any) => void;
}

//  TODO:  Support template types.
//  TODO:  Support type inference.
//  CONSIDER:  Should we support default values for the input / output?   MAYBE generalize with a Template?

//  IDEA:  Instead of GROUPS, consider TAGGING the items??  Then, to make the hierarchy, we just go based on the largest group?  Maybe we show all the tags?
//         MAYBE we support both MODULES AND TAGS?


export enum BlockType {
  Primitive = "primitive",
  User = "user"
}

export interface NamedObjectItem {
  name: string;
  description?: string;
  icon?: HaborIconSelection;
  color?: string;
}

export interface Block extends NamedObjectItem {
  type: string;
  inputSchema?: SDTObject;
  outputSchema?: SDTObject;
  // category?: string;  //  TODO:  Support nested categories.  Maybe derive the category from the "Module"?
  tags: string[];
  isComponent: boolean;  //  Determines whether this Block should be involved in layout.  MAYBE rename to "needsLayout"?
}

export const blockNoun: Noun = {
  id: "block",
  name: "Block",
  description: "Base class for Blocks",
  inherits: ["namedobject"],
  properties: {
    type: {
      name: "type",
      type: { type: "keyword", required: true }  //  TODO:  Use a "reference" field to the BlockTag entity?
    },
    inputSchema: {
      name: "inputSchema",
      type: { type: "sdt", required: false, itemType: "object" }
    },
    outputSchema: {
      name: "outputSchema",
      type: { type: "sdt", required: false, itemType: "object" }
    },
    tags: {
      name: "tags",
      type: { type: "array", required: false, itemType: { type: "keyword" } }  //  TODO:  Use a "reference" field?
    },
    isComponent: {
      name: "isComponent",
      type: { type: "boolean", required: false }
    }
  }
};

export interface PrimitiveBlock extends Block {
  type: BlockType.Primitive;
  //  CONSIDER:  Instead of using React hooks, make a "pipe" method here to deal with data piping?
  //  TODO:  Properly type the input.
  renderer: (props: { [key: string]: any } & { frameworkParams: FrameworkParamsNew }) => any | null;  //  TODO:  SHOULD be (React.ReactElement<any> | null), BUT I'm not sure the SDK / backend should depend on React.
}

//  CONSIDER:  Instead of two separate "UserBlock" and "HaborComponent(New)" blocks, consider making SEVERAL TYPES of "UserBlocks", like "Component" or "Processing", "Inline", etc...  MAYBE we can help build the component with "Mixins" which determine what we should expect the interface to look like?  OR do we do this with Plugins?  IF we do it with Mixins, THEN we need to have something interpret it??  Hmmm... Interesting.
export interface UserBlock extends Block {
  type: BlockType.User;
  blockInsts: BlockInst[];
}


export const serializedUserBlockNoun: Noun = {
  id: "serialized-userblock",
  name: "Serialized User Block",
  description: "A Serialized User-Defined Block.",
  inherits: ["block"],
  properties: {
    type: {  //  TODO-IMPORTANT-GENERAL:  This is ALWAYS set for this class... instead of storing again in EACH instance, consider centralizing on the Class but applying it as an instance variable?
      name: "type",
      type: { type: "keyword", required: true },
      value: BlockType.User
    },
    blockInsts: {
      name: "blockInsts",
      type: { type: "text", required: true }  //  NOTE:  We're stringifying the array of block instances to JSON for now.
    },
    tags: {
      name: "tags",
      type: { type: "array", required: false, itemType: { type: "keyword" } }
    },
    isComponent: {
      name: "isComponent",
      type: { type: "boolean", required: false }
    }
  }
};

export interface SerializedUserBlock extends Pick<UserBlock, Exclude<keyof UserBlock, 'blockInsts'>> {
  blockInsts: string;
}

export interface SerializedPrimitiveBlock extends Pick<PrimitiveBlock, Exclude<keyof PrimitiveBlock, 'renderer'>> {
  renderer: string;
}

export const serializeUserBlock = (userBlock: UserBlock): SerializedUserBlock => {
  return { ...userBlock, blockInsts: JSON.stringify(userBlock.blockInsts) };
};

export const deserializeUserBlock = (serUserBlock: SerializedUserBlock): UserBlock => {
  return { ...serUserBlock, blockInsts: JSON.parse(serUserBlock.blockInsts) };
};

export interface ValueReference {
  type: string;
}

export interface ConstantValueReference {
  type: "constant";
  value: any;
}

export interface SignalValueReference {
  type: "signal";
  signalId: string;
}

export type AnyValueReference = ConstantValueReference | SignalValueReference;

/**
 * Instantiates a block.
 * The input / output are each an array of strings, identifying a particular "signal" or "wire" or "variable".
 */
export interface BlockInst {
  id: string;
  blockId: string;
  input: { [name: string]: AnyValueReference };  //  Map from inputId -> ValueReference
  output: { [name: string]: AnyValueReference };  //  Map from outputId -> ValueReference
}

// export interface HaborComponentNewPrimitive extends PrimitiveBlock {
//   isComponent: true;
//   // type: "component",
//   // element: HaborElementDeclaration;
//   // blockInsts: BlockInst[];
//   // classes?: string[];
//   // settingsSchema?: SDTObject;
// }

// export interface HaborComponentNew extends UserBlock {
//   isComopnent: true;
// }

export type AnyBlock = PrimitiveBlock | UserBlock;

export type AnySerializedBlock = SerializedPrimitiveBlock | SerializedUserBlock;
