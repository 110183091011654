
import * as yup from 'yup';
export const SDTYup = yup.object({
  metadata: yup.object(),
  required: yup.boolean(),
  type: yup.string().required(),
});


export const AllDTTypeArray = ['keyword', 'text', 'number', 'object', 'boolean', 'date', 'option', 'array', 'sdt', 'any'];
