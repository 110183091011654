import * as yup from 'yup';
import { SDT, SDTSchemaProperties, SDTSerializedSchemaProperties } from '../parser-core';
import { DTNumber, DTNumberOptions, DTObject, DTOption } from '../types';
import { SDTObject } from './object';
import { SDTYup } from '../util';

export interface SDTNumber extends SDT {
  type: 'number';
}

const SDTNumberYup = SDTYup.shape({
  type: yup.string().required().matches(/number/),
});

export async function SDTNumberParser<MetadataType = any> (serialized: SDTNumber, metadata?: MetadataType) {

  //  Validate
  const valid = SDTNumberYup.validateSync(serialized);
  if (!valid) { throw new Error(`Number schema validation failed`); }

  const serializedCopy: any = { ...serialized };
  delete serializedCopy['type'];
  const numberOptions: DTNumberOptions = { ...serializedCopy };

  //  Create the DTNumber
  const dtNumber = new DTNumber(numberOptions, metadata);
  return dtNumber;
}

//  Schema to Validate an Number SDT
export const SDTNumberSerializedSchema: SDTObject = {
  type: 'object',
  required: true,
  extensible: false,
  properties: {
    ...SDTSerializedSchemaProperties,
    type: { type: 'option', options: ['number'] }
  }
};

export const SDTNumberSchema = new DTObject({
  required: true,
  extensible: false,
  properties: {
    ...SDTSchemaProperties,
    type: new DTOption({ required: true, options: ['number'] })
  }
});
