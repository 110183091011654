import { SDTObject } from "davel";
import { EntityID, entityIdSchema, InstanceID, ObjectID, instanceIdSchema, objectIdSchema, InstanceFieldID, NounFieldID } from "./identifiers";
import { Noun } from "./noun";

//  TODO:  Add in "Dependency" so when the source changes, the destination is pinged?  MAYBE this is a Rule we set on the Destination?
//  NOTE:  In some cases, we use a Relationship to augment existing things, like an Addable instance and another Object.  This adds a property.  Same with a "Remote Property", where we mount the source value at the destination.
//         In other cases, we use a relationship to express a connection, like connecting a Product to a MealEntry.  This is the typical usage of a "Relationship".  But, this is itself a form of landing pad?  In either case, we're indicates that a particular destination is referencing the source.
//  NOTE:  We want the sub-classes to STILL construct the parent object.  I'm not sure how Python or JS would handle this, BUT, I think that's OK.  We want to set up our object inheritance so it stores in the tables if it CAN.  If a value is overridden with a DIFFERENT type, then we won't be able to store in the original?  I suppose we can NOT support overrides and only support overrides with sub-types?

//
//  Base Relationship
//

export interface Relationship {
  srcId: EntityID;
  destId: EntityID;
}

export const RelationshipSchema: SDTObject = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    srcId: entityIdSchema,
    destId: entityIdSchema
  }
};

export const RelationshipNoun: Noun = {
  name: "relationship",
  id: "relationship",
  description: "A Habor Relationship",
  properties: {
    srcId: {
      name: "srcId",
      type: entityIdSchema
    },
    destId: {
      name: "destId",
      type: entityIdSchema
    }
  }
};


//
//  Ownership Relationship
//  NOTE:  This is just ONE specific system that we use to indicate ownership!  There can be MANY more!!
//

export interface OwnershipRelationship extends Relationship {
  srcId: ObjectID;
  destId: ObjectID;
}

export const OwnershipRelationshipSchema: SDTObject = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    srcId: objectIdSchema,
    destId: objectIdSchema
  }
};

export const OwnershipRelationshipNoun: Noun = {
  name: "OwnershipRelationship",
  id: "ownership-relationship",
  description: "Nests a child (src) under a parent owner (dest).",
  inherits: [RelationshipNoun.id],
  properties: {
    srcId: {
      name: "srcId",
      type: objectIdSchema
    },
    destId: {
      name: "destId",
      type: objectIdSchema
    }
  }
};


//
//  Addon Relationship (TrackMine Concept)
//

export interface AddonRelationship extends Relationship {
  srcId: InstanceID;
  destId: ObjectID;
}

export const AddonRelationshipSchema: SDTObject = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    srcId: instanceIdSchema,
    destId: objectIdSchema
  }
};

export const AddonRelationshipNoun: Noun = {
  name: "AddonRelationship",
  id: "addon-relationship",
  description: "A Habor Relationship",
  inherits: [RelationshipNoun.id],
  properties: {
    srcId: {
      name: "srcId",
      type: instanceIdSchema
    },
    destId: {
      name: "destId",
      type: objectIdSchema
    }
  }
};


//
//  Attachment Relationship (TrackMine Concept)
//

export interface AttachmentRelationship extends Relationship {
  srcId: ObjectID;
  destId: ObjectID | InstanceFieldID | NounFieldID;
}

export const AttachmentRelationshipSchema: SDTObject = {
  type: "object",
  extensible: false,
  required: true,
  properties: {
    srcId: objectIdSchema,
    destId: entityIdSchema //  TODO:  Switch to "objectIdSchema | fieldIdSchema" when aggregate types are supported.
  }
};

export const AttachmentRelationshipNoun: Noun = {
  name: "AttachmentRelationship",
  id: "attachment-relationship",
  description: "A TrackMine Attachment",
  inherits: [RelationshipNoun.id],
  properties: {
    srcId: {
      name: "srcId",
      type: objectIdSchema
    },
    destId: {
      name: "destId",
      type: entityIdSchema
    }
  }
};
