import * as yup from 'yup';
import { DT, DTOptions } from '../type-core';

export interface DTNumberOptions extends DTOptions {
  // min?: number;
  // max?: number;
}

export class DTNumber<MetadataType = any> extends DT<number, MetadataType> {
  public name = 'Number';

  constructor(public options: DTNumberOptions = {}, protected metadata?: MetadataType) {
    super(options);
  }

  public async validate(input: number): Promise<number | undefined> {

    //  Super Validation
    input = await super.validate(input);

    //  Handle Undefined
    if (input === undefined) { return undefined; }

    //  Validate Input
    const valid = await yup.number().isValid(input);
    if (!valid) { this.failValidation(input); }
    return input;
  }

  //  TODO:  We may support multiple DBs, put some thought into using a plugin design
  //         so we don't need a function for each DB.  Maybe if JS had mixins we could use that?
  public async getElasticSchema() {
    return {
        type: 'float' //  TODO:  I'm storing all as floats for now.. is this OK?
    };
  }
}
