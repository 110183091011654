
//  Maybe "Systems" are made of several pluggable pieces, WHICH can include sub-systems?  FOR NOW, we'll just keep it simple and hard-code the pieces.
//  NOTE:  BECAUSE Plugins can depend on one another, it's possible that one Plugin creates new Registration Points that the others can use.

//  NOTE:  Installing should create a new "System"?  It should create a "Development API" which we can use?

//  OK.... So..... instead of actually attaching this DIRECTLY, we COULD attach a HANDLER which WILL eventually call the underlying method.  This way, our before / after hooks will be obeyed.

//  NOTE:  We should be able to access a particular handler with things like "before" / "after", MAYBE by ID?  The problem, is that it makes a lot of sense to have something like that in the UI.  Where we can "see" the handlers and their name / description, etc... BUT, they will contain code?  So... hmmm... that makes more sense, to me, right now, in an IDE like VSCode... Hmmm...
//         Ok.... so.... one important point, is that if a Plugin depends on another, then that Plugin SHOULD have access to everything the others have exported.  MAYBE, in some cases, we DON'T export?  This means we can't hook.  That MIGHT be ok.  POTENTIALLY better than an unbounded exporting with after / before hooks on everything?  MAYBE some of the exports DON'T have that type of interface!  But they have some OTHER interface?? OK cool, EITHER WAY, the develop will know that they exist, so they can use them.  MAYBE, they are explicit METHODS defined on the dependency "classes"?

//  NOTE:  Instead of a "Core", there are individual Plugins for EACH core element, like "Express"?

//  NOTE:  This entire Plugin architecture is setting up the ORDER in which operations occur.  Normally this is hard-coded, but here, it is selected by each Plugin making it VERY clear which elements are dependent?

//  CONSIDER:  Should we support un-installation?

// export type PluginMap = { [name: string]: HaborPlugin };

export type handler = (dependencies: CorePluginClass[]) => void;

export interface Program {

  //  Registers code to be run.
  registerCode: (handler: handler) => void;
}

// export interface CoreExports {}

/**
 * Base Plugin Contract
 * NOTE:  We use an abstract class to group the functions and give us access to TS decorators.
 */

 /**
  * NOTE:  We use this "details" object because we cannot add a static "name" field to a class.
  */
 export interface CorePluginDetails {
  id: string;
  name: string;
  description?: string;
  dependencies: string[];  //  TODO:  Eventually support Plugin versions.
 }
//  export interface CorePlugin {
//    details: CorePluginDetails;
//    install: (program: Program, imports: any) => any;
//  }

 export class CorePluginClass {
  //  TODO:  I want these to be required fields in the
 public static details: CorePluginDetails;
 public install: (program: Program, imports: any) => any = () => {};

 //  TODO:  Don't hard-code on the plugin!
 public static optionsComponent: () => any;
}

// export abstract class CorePlugin {
//   // public static registers: HaborRegisters;
//   public static dependencies: CorePlugin[];
//   //  CONCERN:  If we only pass "dependencies" to the install method, then we can only access the GLOBAL plugins.  What about instances?  I'm not sure... will iterate on this.  We MAY want to pass "Program" too?  Maybe we want to namespace the things created by each plugin so they aren't just attached to the raw Plugin?  Like "programElements" or something??  Hmm... idk, that seems strange too..
//   //  UPDATE:  I guess we still need "Program" to update the core code when that's necessary?  Hmmm... MAYBE consider just going that in a "Core" Plugin of some sort.  Then ALL we need is "dependencies" right?
//   public static install (program: Program, dependencies: CorePlugin[]): CoreExports {
//     throw new Error("Install must be implemented in a sub-class.");
//   }
// }