import { DTKeyword, DTObject, SDTObject } from "davel";

export const serializedEntitySchemaObjectProperties = {
  id: { type: "keyword", required: true }
};

export const entitySerializedSchema: SDTObject = {
  type: "object",
  required: true,
  extensible: false,
  properties: {
    ...serializedEntitySchemaObjectProperties
  }
};

export const entitySchemaObjectProperties = {
  id: new DTKeyword({ required: true })
};
export const entitySchema = new DTObject({
  required: true,
  extensible: false,
  properties: entitySchemaObjectProperties
});
