import { SDTObject } from "davel";

export enum EntityType {
  Noun = 'noun',
  NounField = 'noun-field',
  Instance = 'instance',
  InstanceField = 'instance-field'
}

export const objectTypes = Object.keys(EntityType).map((key: any) => (EntityType[key]));

export const nounIdSchema: SDTObject = {
  type: "object",
  extensible: false,
  properties: {
    nounId: {
      type: "keyword",
      required: true
    },
    type: {
      type: "option",
      options: [EntityType.Noun]
    }
  }
};

export interface NounId {
  nounId: string;
  type: EntityType.Noun;
}


export const instanceIdSchema: SDTObject = {
  type: "object",
  extensible: false,
  properties: {
    nounId: {
      type: "keyword",
      required: true
    },
    instanceId: {
      type: "keyword",
      required: true
    },
    type: {
      type: "option",
      options: [EntityType.Instance]
    }
  }
};

export interface InstanceID {
  nounId: string;
  instanceId: string;
  type: EntityType.Instance;
}


//
//  InstanceFieldID
//
export const instanceFieldIdSchema: SDTObject = {
  type: "object",
  extensible: false,
  properties: {
    nounId: {
      type: "keyword",
      required: true
    },
    instanceId: {
      type: "keyword",
      required: true
    },
    fieldPath: {  //  TODO-IMPORTANT:  This is a SPECIAL field which encodes a path.  Currently, we store in the backend with a dotted string, BUT we'd like it to be automatically converted to / from an ARRAY of string.  This means we MAY want to have pre / post DB transforms?  FOR NOW, let's just do the parsing manually and store as a string.
      type: "keyword",
      required: true
    },
    type: {
      type: "option",
      options: [EntityType.InstanceField]
    }
  }
};

export interface InstanceFieldID {
  nounId: string;
  instanceId: string;
  fieldPath: string;
  type: EntityType.InstanceField;
}

//
//  NounFieldID
//

export const nounFieldIdSchema: SDTObject = {
  type: "object",
  extensible: false,
  properties: {
    nounId: {
      type: "keyword",
      required: true
    },
    fieldPath: {
      type: "keyword",
      required: true
    },
    type: {
      type: "option",
      options: [EntityType.NounField]
    }
  }
};

export interface NounFieldID {
  nounId: string;
  fieldPath: string;
  type: EntityType.NounField;
}


//
//  ObjectID:  A Class or Instance
//

export const objectIdSchema: SDTObject = {
  type: "object",
  extensible: false,
  properties: {
    nounId: {
      type: "keyword",
      required: true
    },
    instanceId: {
      type: "keyword",
      required: false
    },
    type: {
      type: "option",
      options: [EntityType.Noun, EntityType.Instance]
    }
  }
};

export interface ObjectID {
  nounId: string;
  instanceId?: string;
  type: EntityType.Instance | EntityType.Noun;
}


//
//  EnityID:  A Class, Instance, Noun Field, or Instance Field
//
export const entityIdSchema: SDTObject = {
  type: "object",
  extensible: false,
  properties: {
    nounId: {
      type: "keyword",
      required: true
    },
    instanceId: {
      type: "keyword",
      required: false
    },
    fieldPath: {
      type: "keyword",
      required: false
    },
    type: {
      type: "option",
      options: objectTypes,
      required: true
    }
  }
};

export interface EntityID {
  nounId: string;
  instanceId?: string;
  fieldPath?: string;
  type: EntityType;
}
